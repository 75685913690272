import { Col, Dropdown, Menu, Row, Space } from 'antd'
import React, { useState } from 'react'
import Filter from './Filter'
import Header from './header'
import PaymentTable from './Table/PaymentTable'
import { getPaymentHistory } from 'api/family'
import { useQuery } from '@tanstack/react-query'
import Spinner from 'components/Spinner'
export const Payments = familyId => {
  const [selectedRows, setSelectedRows] = useState([])
  const [hiddenColumns, setHiddenColumns] = useState<any>({
    name: true,
    payment: true,
    amount: true,
    date: true,
    status: true,
    action: true,
  })
  const [parms, setParms] = useState<any>({
    page: 1,
    limit: 10,
    status: 'success',
  })
  const { isLoading, data, refetch } = useQuery(
    ['getPaymentHistory', familyId?.familyId, parms],
    () => getPaymentHistory(familyId?.familyId, parms),
  )
  const handleSearch = (query: string) => {
    setParms({
      ...parms,
      searchString: query,
    })
  }
  return (
    <>
      <Header data={data}></Header>
      <Row>
        <Col style={{ width: '100%' }}>
          <Filter
            hidCols={hiddenColumns}
            setCols={setHiddenColumns}
            search={handleSearch}
            parms={parms}
            setParms={setParms}
          ></Filter>
        </Col>

        <Col style={{ alignItems: 'center', width: '100%' }}>
          {isLoading ? (
            <div
              className="flex justify-center aligin-center items-center "
              style={{ height: '80vh' }}
            >
              <Spinner />
            </div>
          ) : (
            <PaymentTable
              setSelectedRows={setSelectedRows}
              hidCols={hiddenColumns}
              data={data}
              parms={parms}
              setParms={setParms}
              // deleteMulti={deleteMultipleRecords}
            />
          )}
        </Col>
      </Row>
    </>
  )
}
export default Payments
