import React, { useState } from 'react'
import { Modal, Radio } from 'antd'
import closeIcon from 'assets/Images/close.svg'
import '../styles/additional-settings.scss'
import { creationEventStore } from 'state/eventCreationStore'

interface SecurityModalProps {
  openModal: boolean
  closeModal: React.Dispatch<React.SetStateAction<boolean>>
}

const SecurityModal: React.FC<SecurityModalProps> = ({
  openModal,
  closeModal,
}) => {
  // todo proper type later
  const step5Payload: any = creationEventStore(store => store.step5Payload)
  const setStep5Payload = creationEventStore(store => store.setStep5Payload)
  const [selectedOption, setSelectedOption] = useState<
    'low' | 'medium' | 'high'
  >('low')
  const handleCancel = () => {
    closeModal(false)
  }
  const hanldeSave = () => {
    setStep5Payload({ ...step5Payload, riskLevel: selectedOption })
    handleCancel()
  }
  return (
    <div>
      <Modal
        open={openModal}
        onCancel={handleCancel}
        className="security-modal"
        footer={false}
        width={500}
        centered
      >
        <div className="flex-center pt-20px justify-between ">
          <h2 className="w-600 text-18">Security level</h2>
          <img
            src={closeIcon}
            alt="close-button"
            onClick={handleCancel}
            className="close button pointer"
          />
        </div>
        <div className="mt-20px">
          <div>
            <Radio.Group
              className="security flex flex-col gap-20px"
              defaultValue={step5Payload.riskLevel}
              // onChange={e =>
              //   setStep5Payload({ ...step5Payload, riskLevel: e.target.value })
              // }
              onChange={e => setSelectedOption(e.target.value)}
            >
              <Radio value={'low'} className="mr-40 radio">
                <div>
                  <h3 className="text-15 w-500">Low risk</h3>
                  <p className="text-gray">
                    Self check-in for family events + auto check-out{' '}
                  </p>
                </div>
              </Radio>
              <Radio value={'medium'} className="mr-40 radio">
                <div>
                  <h3 className="text-15 w-500">Medium risk</h3>
                  <p className="text-gray">Check-in + Check-out or signature</p>
                </div>
              </Radio>
              <Radio value={'high'} className="mr-40 radio">
                <div>
                  <h3 className="text-15 w-500">High risk</h3>
                  <p className="text-gray">Check-in + Check-out or signature</p>
                </div>
              </Radio>
            </Radio.Group>
          </div>
        </div>
        <div className="mt-30px flex gap-20px justify-end align-center">
          <button className="confirm-btn pointer" onClick={hanldeSave}>
            Save
          </button>
        </div>
      </Modal>
    </div>
  )
}

export default SecurityModal
