import { infoMessage } from 'api/global'
import { Map } from 'immutable'
import moment, { Moment } from 'moment'
import { generalProfile, Person } from 'types/globalTypes'

export const daysOfWeek = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
]

export function clearToken() {
  localStorage.removeItem('auth_token')
  localStorage.removeItem('user')
  localStorage.removeItem('role')
  localStorage.removeItem('adminId')
  localStorage.removeItem('organizationId')
}
/**
 * Gets the token.
 *
 * @return     {Map}  The token.
 */
export function getToken() {
  try {
    const authToken = localStorage.getItem('auth_token')
    const user = localStorage.user ? JSON.parse(localStorage.user) : null
    return new (Map as any)({ authToken, user })
  } catch (err) {
    clearToken()
    return new (Map as any)()
  }
}

export function capitalizeFirstLetter(string: string) {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1)
}
export function getUserName(user: Person | generalProfile | undefined) {
  const isPerson = (obj: any): obj is Person => {
    return obj !== null && typeof obj === 'object' && 'firstName' in obj
  }

  const getFullName = (firstName: string, lastName: string): string => {
    const fullName = `${firstName} ${lastName}`
    return fullName.length > 24 ? firstName : fullName
  }

  return capitalizeFirstLetter(
    isPerson(user)
      ? getFullName(user?.firstName || '', user?.lastName || '')
      : user?.name || '',
  )
}
export function isEmpty(obj: any) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false
  }
  return true
}

export function setPaginationObject(
  currentPagination: any,
  pagination: any,
  filters: any,
  sorter: any,
) {
  // console.log("check:",currentPagination,pagination,filters,sorter);
  let newPaginationInfo = {
    ...currentPagination,
    showTotal: (total: any, range: any) =>
      `Showing ${range[0]} to ${range[1]} of ${total} records`,
    current: isEmpty(currentPagination) ? 1 : currentPagination.current,
    pageSize: isEmpty(currentPagination) ? 10 : currentPagination.pageSize,
  }

  if (filters) {
    newPaginationInfo.search = []
    for (const key in filters) {
      if (filters[key]) {
        newPaginationInfo.search.push({
          field: key,
          value: filters[key][0],
        })
      }
    }
  }

  if (sorter.field) {
    newPaginationInfo.sort = {
      field: sorter.field,
      order: sorter.order === 'ascend' ? 'asc' : 'desc',
    }
  }

  return newPaginationInfo
}
export function paginationInfo(
  currentPage: number,
  count: number,
  limit?: number,
) {
  let PaginationInfo: any = {
    total: count,
    current: currentPage,
    limit: limit ? limit : 10,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20'],
    showTotal: (total: any, range: any) =>
      `Showing ${range[0]} to ${range[1]} of ${total} records`,
  }
  return PaginationInfo
}
export const getNameSignature = (name?: string) => {
  if (name === undefined || name.length === 0) {
    return ' '
  } else if (name.length === 1) {
    return name.toUpperCase()
  } else {
    const words = name.split(' ')
    if (words.length === 1) {
      return words[0].charAt(0).toLocaleUpperCase()
    } else {
      const word = words[0].charAt(0) + words[1].charAt(0)
      return word.toUpperCase()
    }
  }
}
export const forceRefetch = (key: string, queryClient: any) => {
  const keysToRemove = [key]
  queryClient.removeQueries(keysToRemove)
}
const startOfLocalDayInUTC = (date: Date | string) => {
  const newDate = new Date(date)
  newDate.setHours(0, 0, 0, 0)
  return date
}
export const convertToUtc = (date: string | Date | any, format?: string) => {
  // return moment(date).startOf('day').utc(date).format(format)
  return moment.utc(startOfLocalDayInUTC(date)).format(format)
}
export const convertToLocal = (
  date: string | Date,
  format?: string,
  useSpecialFormatting?: boolean,
) => {
  var utcMoment = moment.utc(date)
  var localOffsetMinutes = moment().utcOffset()
  var localMoment = utcMoment.utcOffset(localOffsetMinutes)

  if (useSpecialFormatting) {
    var now = moment()
    var diffInDays = now.diff(localMoment, 'days')

    if (diffInDays === 0) {
      return 'Today ' + localMoment.format('hh:mm A')
    } else if (diffInDays === 1) {
      return 'Yesterday ' + localMoment.format('hh:mm A')
    } else {
      return localMoment.format('MMM DD YYYY hh:mm A')
    }
  } else if (!format) {
    return localMoment.format('MMMM DD, YYYY')
  } else {
    return localMoment.format(format)
  }
}

export const calculateTimeDiffernce = (
  startTime: Date | string,
  endTime: Date | string,
  diffIn?: 'minutes' | 'hours' | 'days' | 'months',
) => {
  const startMoment = moment(startTime, 'h:mm a')
  const endMoment = moment(endTime, 'h:mm a')
  return endMoment.diff(startMoment, diffIn || 'minutes')
}

export const parseParams = (params: any) => {
  const keys = Object.keys(params)
  let options = ''

  keys.forEach(key => {
    const isParamTypeObject = typeof params[key] === 'object'
    const isParamTypeArray = isParamTypeObject && params[key].length >= 0

    if (!isParamTypeObject) {
      options += `${key}=${params[key]}&`
    }

    if (isParamTypeObject && isParamTypeArray) {
      params[key].forEach((element: any) => {
        options += `${key}=${element}&`
      })
    }
  })

  return options ? options.slice(0, -1) : options
}
export const clearFilters = (
  item: string,
  parms: Record<string, any>,
  setParms: React.SetStateAction<any>,
  setFn?: any,
) => {
  if (item in parms) {
    const { [item]: _, ...updatedParms } = parms
    setParms(updatedParms)
  }
  setFn && setFn('')
}
export const convertDOBToAge = (dob: string | Date) => {
  const dobMoment = moment.utc(dob)
  const today = moment.utc()
  const age = today.diff(dobMoment, 'years')
  return age
}

export const convertTimeToMinutes = time => {
  const [hours, minutes] = time.split(':')
  return parseInt(hours) * 60 + parseInt(minutes)
}

export const convertTimeToUtcMinutes = (time: string) => {
  const localTime = moment(time, 'hh:mm A')
  const utcTime = localTime.utc()
  return utcTime.hours() * 60 + utcTime.minutes()
}

export function convertMinutesToLocalTime(time: number, format?: string) {
  const milliseconds = time * 60 * 1000
  const localTime = moment.utc(milliseconds).local()
  return localTime.format(format || 'hh:mm A')
}

export const disabledHours = (startTime: number, endTime: number) => {
  const disabledHours = []
  if (startTime < endTime) {
    for (let i = 0; i < startTime; i++) {
      disabledHours.push(i)
    }
    for (let i = endTime; i < 24; i++) {
      disabledHours.push(i)
    }
  } else {
    for (let i = endTime; i < startTime; i++) {
      disabledHours.push(i)
    }
  }
  return disabledHours
}

export const disabledMinutes = (
  startHour: number,
  endHour: number,
  startMinute: number,
  endMinute: number,
  selectedHour: number,
) => {
  const disabledMinutesArray = []
  let isAm = false

  if (selectedHour > 12) {
    isAm = true
  }

  if (selectedHour === startHour) {
    for (let i = 0; i < startMinute; i++) {
      disabledMinutesArray.push(i)
    }
  }

  if (selectedHour === endHour) {
    for (let i = endMinute + 1; i < 60; i++) {
      // disabledMinutesArray.push(i)
      if (i !== 0) {
        disabledMinutesArray.push(i)
      }
    }
  }
  return disabledMinutesArray
}

export const timeValidationCheck = (
  time: Moment,
  startTime: number,
  endTime: number,
  startMinute: number,
  endMinute: number,
) => {
  const selectedHour = parseInt(time.format('HH'), 10)
  const selectedMinute = parseInt(time.format('mm'), 10)

  if (
    selectedHour < startTime ||
    (selectedHour === startTime && selectedMinute < startMinute)
  ) {
    const adjustedTime = moment().hour(startTime).minute(startMinute)
    return adjustedTime
  } else if (
    selectedHour > endTime ||
    (selectedHour === endTime && selectedMinute > endMinute)
  ) {
    const adjustedTime = moment().hour(endTime).minute(endMinute)
    return adjustedTime
  } else {
    return time
  }
}

export const formValidated = <T extends {}>(validationObj: T): boolean => {
  let validated = true
  for (const [key, value] of Object.entries(validationObj)) {
    if (value === '' || value === undefined || value === null) {
      infoMessage(`${capitalizeFirstLetter(key)} is Required`)
      validated = false
      break
    }
  }
  return validated
}

export const metersToInches = (meters: number) => {
  const inches = meters * 39.3701
  return inches.toFixed(2)
}
export const kgToPounds = (weight: number) => {
  const kg = weight * 2.20462
  return kg.toFixed(2)
}
export const convertToLocalWithAt = (
  date: string | Date,
  format: string = 'DD MMM YYYY [at] h:mm A',
  useSpecialFormatting?: boolean,
) => {
  var utcMoment = moment.utc(date)
  var localOffsetMinutes = moment().utcOffset()
  var localMoment = utcMoment.utcOffset(localOffsetMinutes)

  if (useSpecialFormatting) {
    var now = moment()
    var diffInDays = now.diff(localMoment, 'days')

    if (diffInDays === 0) {
      return 'Today ' + localMoment.format('hh:mm A')
    } else if (diffInDays === 1) {
      return 'Yesterday ' + localMoment.format('hh:mm A')
    } else {
      return localMoment.format('MMM DD YYYY [at] hh:mm A')
    }
  } else {
    return localMoment.format(format)
  }
}
// export const convertToLocalExportTicketTime = (
//   date,
//   format = 'MMMM DD, YYYY',
//   useSpecialFormatting = false,
// ) => {
//   if (!date) return ''

//   if (typeof date === 'string' && date.includes('GMT')) {
//     return date.replace(
//       /\b(\w{3} \w{3} \d{2} \d{4} \d{2}:\d{2}:\d{2} GMT[+-]\d{4})\b/g,
//       match => {
//         const localMoment = moment.utc(match).local()
//         return localMoment.format('DD MMM YYYY [at] hh:mm A')
//       },
//     )
//   }

//   const utcMoment = moment.utc(date)
//   const localMoment = utcMoment.local()

//   if (useSpecialFormatting) {
//     const now = moment()
//     const diffInDays = now.diff(localMoment, 'days')

//     if (diffInDays === 0) {
//       return 'Today ' + localMoment.format('hh:mm A')
//     } else if (diffInDays === 1) {
//       return 'Yesterday ' + localMoment.format('hh:mm A')
//     } else {
//       return localMoment.format('MMM DD YYYY hh:mm A')
//     }
//   } else {
//     return localMoment.format(format)
//   }
// }

// export const convertToLocalExportTicketTime = (tickets) => {
//   if (!tickets || tickets.length === 0) return ''

//   const formatTicket = (ticket) => {
//     const { name, days, timings } = ticket
//     const formattedDays = days.map(day => moment.utc(day).local().format('ddd MMM DD YYYY hh:mm A')).join(', ')
//     return `${name} { ${formattedDays} }`
//   }

//   return tickets.map(ticketObj => {
//     const mainTicketStr = formatTicket(ticketObj.ticket)
//     const additionalTicketsStr = ticketObj.additionalTickets.map(formatTicket).join(' - ')
//     return `${mainTicketStr} - ${additionalTicketsStr}`
//   }).join(', ')
// }

export const convertToLocalExportTicketTime = tickets => {
  if (!tickets || tickets.length === 0) return ''

  const formatTicket = ticket => {
    const { name, days, timings } = ticket
    const formattedDays = days
      .map(day => moment.utc(day).local().format('ddd MMM DD YYYY'))
      .join(', ')
    const startTime = convertMinutesToLocalTime(timings.startTime)
    const endTime = convertMinutesToLocalTime(timings.endTime)
    return `${name} { ${formattedDays} at ${startTime} - ${endTime} }`
  }

  return tickets
    .map(ticketObj => {
      const mainTicketStr = formatTicket(ticketObj.ticket)
      const additionalTicketsStr = ticketObj.additionalTickets
        .map(formatTicket)
        .join(' - ')
      return `${mainTicketStr} - ${additionalTicketsStr}`
    })
    .join(', ')
}

export const convertToLocalRegistrationTime = date => {
  const localMoment = moment.utc(date).local()
  const registrationDate = localMoment.format('DD MMM, YYYY')
  const registrationTime = localMoment.format('hh:mm A')
  return { registrationDate, registrationTime }
}
