import React, { useState } from 'react'
import { Row, Col, Form, Modal, Input, Button, Select } from 'antd'
import '../styles/modal-styles.scss'
import { creationEventStore } from 'state/eventCreationStore'
import closeIcon from 'assets/Images/close.svg'
import TicketColors from '../TicketColors'
import { useCreateDropinTicket } from 'api/EventCreation/CreationStep4'
import { DropinTicketPayload, DropinTicketResp } from 'types/eventStep4'
import ButtonSpinner from 'components/global/ButtonSpinner'

interface DropInTicketsProps {
  modalOpen: boolean
  modalClose: React.Dispatch<React.SetStateAction<boolean>>
  data?: DropinTicketResp
}

const DropInTickets: React.FC<DropInTicketsProps> = ({
  modalOpen,
  modalClose,
  data,
}) => {
  const [form] = Form.useForm()
  const { _id } = creationEventStore(store => store.eventData)
  const setEventData = creationEventStore(store => store.setEventData)
  const [ticketColor, setTicketColor] = useState<string>(
    data ? data.color : undefined,
  )
  const { mutate: dropinTicketCreate, isLoading: dropInTicketLoading } =
    useCreateDropinTicket()
  const formSchema = {
    name: data ? data.name : undefined,
    price: data ? data.price : undefined,
    priceUnit: data ? data.priceUnit : undefined,
    description: data ? data?.description : undefined,
  }

  const handleCancel = () => {
    modalClose(false)
  }

  const onFinish = (values: DropinTicketPayload) => {
    const payload: DropinTicketPayload = {
      ...values,
      color: ticketColor,
    }
    dropinTicketCreate(
      { payload, eventId: _id, ticketId: data?._id },
      {
        onSuccess: result => {
          setEventData(result)
          modalClose(false)
        },
      },
    )
  }

  return (
    <div>
      <Modal
        open={modalOpen}
        onCancel={handleCancel}
        className="drop-in-modal"
        footer={false}
        centered
      >
        <div className="flex-center pt-20px justify-between ">
          <h2 className="w-600 text-18">Drop-in Ticket</h2>
          <img
            src={closeIcon}
            alt="close-button"
            onClick={handleCancel}
            className="close button pointer"
          />
        </div>
        <div className="h-25rem mt-20px overflow-auto-y overflow-hidden-x scroller">
          <Form
            form={form}
            layout="vertical"
            initialValues={formSchema}
            onFinish={onFinish}
          >
            <Row gutter={10}>
              <Col>
                <label className="input-label required-input">
                  Ticket title
                </label>
              </Col>
              <Col>
                <Form.Item className="form-control" name="name">
                  <Input
                    placeholder="Ticket title"
                    className="form-control-field"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={10}>
              <Col>
                <label className="input-label required-input">Price</label>
              </Col>
              <div
                className="flex align-center gap-20px mx-auto"
                style={{ width: '98%' }}
              >
                <div className="w-30p flex">
                  <p className="mt-15px mr-10px">$</p>
                  <Form.Item name="price">
                    <Input
                      type="number"
                      placeholder="0"
                      className="text-end br-12"
                    />
                  </Form.Item>
                </div>
                <div className="w-70p">
                  <Form.Item
                    className="pointer selector-input"
                    name="priceUnit"
                  >
                    <Select
                      className="select-input-field "
                      placeholder="Select"
                      optionLabelProp="label"
                      options={[
                        {
                          value: 'minute',
                          label: 'Per Minute',
                        },
                        {
                          value: 'hour',
                          label: 'Per hour',
                        },
                        {
                          value: 'day',
                          label: 'Per day',
                        },
                      ]}
                    />
                  </Form.Item>
                </div>
              </div>
            </Row>
            <Row className="mt-10px">
              <Col span={24}>
                <label htmlFor="" className="custom-field-label">
                  Description
                </label>
                <Form.Item name="description">
                  <Input.TextArea
                    className="note-field"
                    placeholder="Terms of usage of the ticket"
                    autoSize={{ minRows: 3, maxRows: 10 }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <label className="input-label required-input">
                  Select colors
                </label>
                <TicketColors
                  ticketColor={ticketColor}
                  setTicketColor={setTicketColor}
                />
              </Col>
            </Row>
            <Row justify="end" className="mt-20px">
              <Button
                className="stepper-button"
                disabled={dropInTicketLoading}
                htmlType="submit"
              >
                {dropInTicketLoading ? (
                  <ButtonSpinner size={20} color="#000" />
                ) : (
                  'Save'
                )}
              </Button>
            </Row>
          </Form>
        </div>
      </Modal>
    </div>
  )
}

export default DropInTickets
