import React, { useCallback, useMemo, useState } from 'react'
import ReactQuill from 'react-quill'
import { modules, formats, customValues } from './EditorTollbar'
import 'react-quill/dist/quill.snow.css'
import './style.scss'

interface QuillInputProps {
  text: string
  setText: React.Dispatch<React.SetStateAction<string>>
}

const QuillInput: React.FC<QuillInputProps> = ({ text, setText }) => {
  const handleChange = (value: string) => {
    setText(value)
  }

  return (
    <div className="editor-input">
      <ReactQuill
        value={text}
        onChange={handleChange}
        modules={modules}
        formats={formats}
      />

      <div className="flex align-center gap-10px main-wrappper-values mt-20px scroller">
        {customValues.map((item, index) => {
          return (
            <div
              key={index}
              className="custom-value-param pointer"
              onClick={() => setText(prev => `${prev} <b>${item.value}</b>`)}
            >
              <p className="text-15 w-500">{item.name}</p>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default QuillInput
