import React, { useState, useEffect } from 'react'
import { Modal } from 'antd'
import closeIcon from 'assets/Images/close.svg'
import {
  useAddActivityStep3,
  useGetOrgInventoryList,
} from 'api/EventCreation/CreationStep2'
import Loader from 'helper/Loader'
import EmptyData from 'components/global/EmptyData'
import '../style/styles.scss'
import AddSupplyForm from './AddSupplyForm'
import { creationEventStore } from 'state/eventCreationStore'
import { getOrgInventoryList } from 'api/EventCreation/CreationStep2'
import { InventoryResponse } from 'types/events'
import ViewSupplyModal from './ViewSupplyModal'
import ButtonSpinner from 'components/global/ButtonSpinner'
import noImage from 'assets/Images/noImage.jpg'

interface SupplyModalProps {
  modalOpen: boolean
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const SupplyModal: React.FC<SupplyModalProps> = ({
  modalOpen,
  setModalOpen,
}) => {
  const inventoryId = undefined //todo this will come dynamically
  const { organizationId } = creationEventStore(store => store.eventData)
  const activity = creationEventStore(store => store.customActivity)
  const setCustomActivity = creationEventStore(store => store.setCustomActivity)
  const [openSupplyformModal, setOpenSupplyFormModal] = useState<boolean>(false)
  const [openViewModal, setOpenViewModal] = useState<boolean>(false)
  const [supplyData, setSupplyData] = useState<InventoryResponse | undefined>()
  const [selectedSupplies, setSelectedSupplies] = useState<string[]>([])
  // const {
  //   data: supplyData,
  //   isLoading: supplyDataLoading,
  //   isFetching: supplyDataFetching,
  //   refetch: supplyDataRefetch,
  // } = useGetSupplyInventoryById(inventoryId)
  const {
    data: inventoryData,
    isLoading: inventoryLoading,
    isFetching: inventoryFetching,
    refetch: inventoryRefetch,
  } = useGetOrgInventoryList(organizationId)

  const { mutate: addActivityMutate, isLoading: addActivityLoading } =
    useAddActivityStep3()

  const handleCancel = () => {
    setModalOpen(false)
  }

  const hanldeViewSupply = (item: InventoryResponse) => {
    setSupplyData(item)
    setOpenViewModal(true)
  }

  const handleSelectActivity = (inventory: InventoryResponse) => {
    const isSelected = selectedSupplies.includes(inventory._id)
    if (isSelected) {
      setSelectedSupplies(selectedSupplies.filter(id => id !== inventory._id))
    } else {
      setSelectedSupplies([...selectedSupplies, inventory._id])
    }
  }

  const hanldeAddActivity = () => {
    const payload = {
      supplyIds: selectedSupplies,
    }
    addActivityMutate(
      { payload: payload, activityId: activity._id },
      {
        onSuccess: data => {
          setCustomActivity(data)
          handleCancel()
        },
      },
    )
  }

  useEffect(() => {
    inventoryRefetch()
  }, [inventoryId])

  return (
    <div>
      <Modal
        open={modalOpen}
        onCancel={handleCancel}
        className="select-activityModal"
        footer={false}
      >
        <div className="flex-center pt-20px justify-between ">
          <h2 className="w-600 text-18">Select and click to add</h2>
          <img
            src={closeIcon}
            alt="close-button"
            onClick={handleCancel}
            className="close button pointer"
          />
        </div>

        <div className="supply-add scroller overflow-auto-y">
          {!organizationId ? (
            <div className="empty-container flex flex-col align-center justify-center">
              <EmptyData message="No Supplies" />
              <p>You can create new supplies</p>
            </div>
          ) : inventoryLoading || inventoryFetching ? (
            <div className="empty-container flex items-center justify-center">
              <Loader />
            </div>
          ) : (
            <>
              {inventoryData.length === 0 ? (
                <div className="empty-container flex flex-col align-center justify-center">
                  <EmptyData message="No Supplies" />
                  <p>You can create new supplies</p>
                </div>
              ) : (
                <div className="mt-20px h-30rem overflow-auto-y overflow-hidden-x scroller">
                  {inventoryData.map((inventory: InventoryResponse) => {
                    return (
                      <div key={inventory._id} className="mt-20px">
                        <div className="flex gap-20px align-center">
                          <img
                            src={inventory.image?.post || noImage}
                            alt="inventory-img"
                            className="radius-10"
                            style={{ width: '180px', height: '130px' }}
                          />
                          <div>
                            <h3 className="text-16">{inventory.name}</h3>
                            <p>{`${inventory.storageLocations[0].locationId.title}: ${inventory.storageLocations[0].locationId.country}`}</p>
                            <p>
                              {`${inventory.totalQuantity} unit + ${inventory.price}`}
                            </p>
                            <div className="flex flex-wrap align-center gap-10px">
                              {inventory.group.map((item, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="border-1px px-5 py-5 radius-4"
                                  >
                                    {item}
                                  </div>
                                )
                              })}
                            </div>
                            <div className="flex align-center gap-16px">
                              <button
                                className="mt-10 small-btn"
                                onClick={() => hanldeViewSupply(inventory)}
                              >
                                View
                              </button>
                              <button
                                className="mt-10 small-btn"
                                onClick={() => handleSelectActivity(inventory)}
                              >
                                {selectedSupplies.includes(inventory._id)
                                  ? 'Selected'
                                  : 'Select'}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                  <div className="w-full flex justify-center">
                    <button
                      className="mt-20px big-btn"
                      disabled={
                        selectedSupplies.length === 0 || addActivityLoading
                      }
                      onClick={hanldeAddActivity}
                    >
                      {addActivityLoading ? (
                        <ButtonSpinner color="#000" size={20} />
                      ) : (
                        'Add to activity'
                      )}
                    </button>
                  </div>
                </div>
              )}
            </>
          )}
        </div>

        <div className="mt-10px flex align-center justify-between">
          <button className="create-activity-btn" onClick={handleCancel}>
            Cancel
          </button>
          <button
            className="confirm-btn"
            onClick={() => setOpenSupplyFormModal(true)}
          >
            Add new supply
          </button>
        </div>
      </Modal>

      {openSupplyformModal && (
        <AddSupplyForm
          modalOpen={openSupplyformModal}
          modalClose={setOpenSupplyFormModal}
        />
      )}
      {openViewModal && (
        <ViewSupplyModal
          modalOpen={openViewModal}
          setModalOpen={setOpenViewModal}
          data={supplyData}
          selectedSupplies={selectedSupplies}
          setSelectedSupllies={setSelectedSupplies}
        />
      )}
    </div>
  )
}

export default SupplyModal
