import React, { useState, useEffect } from 'react'
import AppLayout from '../../../../components/layout/layout'
import { connect, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import actions from '../../../../Redux/Events/action'
import GeneralDetail from './Screens/GeneralDetails/GeneralDetail'
// import ScheduleActivities from './Screens/ScheduleAndActivities/ScheduleActivities'
import ScheduleAndActivities from './Screens/Step2/ScheduleAndActivities'
import Supplies from './Screens/SupplyAndActivities/Supplies'
// import ManageTickets from './Screens/ManageTickets/ManageTickets'
import ManageTickets from './Screens/Step4/ManageTickets'
import AdditionalEvents from './Screens/AdditionalEvents/AdditionalEvents'
import { EventActivityType } from 'types/events'
import { useAppSelector } from '../../../../Redux/hooks'
import { EventType } from 'types/events'
import { creationEventStore } from 'state/eventCreationStore'
import AdditionalEventSettings from './Screens/Step5/AdditionalEventSettings'
import { useLocation } from 'react-router-dom'
import { useGetEventById } from 'api/EventCreation/EventCreation'

export type CreateEventProps = {
  currentEvent: EventType
  currentData?: any | null
}

const CreateEvent: React.FC<CreateEventProps> = ({ currentData }) => {
  const location = useLocation<{ id: string }>()
  const stepCounter = creationEventStore(store => store.stepCounter)
  const setStepCounter = creationEventStore(store => store.setStepCounter)
  const setEventData = creationEventStore(store => store.setEventData)
  const [data, setData] = useState(currentData || {})
  const id = location.state?.id

  const {
    data: getEventData,
    isLoading: eventDataLoading,
    isFetching: eventDataFetching,
    refetch: eventDataRefetch,
  } = useGetEventById(id)

  useEffect(() => {
    if (id) {
      eventDataRefetch()
    }
  }, [])

  useEffect(() => {
    setEventData(getEventData)
  }, [getEventData])

  const next = (childData: any) => {
    setData(childData)
    setStepCounter(stepCounter + 1)
  }

  const prev = () => {
    setStepCounter(stepCounter - 1)
  }

  return (
    <AppLayout id={id}>
      {stepCounter === 0 && (
        <div
          className="create-event-main-content-wapper w-100"
          style={{ padding: '0px 40px 0px 40px' }}
        >
          <GeneralDetail handleNext={next} />{' '}
        </div>
      )}
      {stepCounter === 1 && (
        <div className="w-100 create-event-main-wapper">
          <ScheduleAndActivities handleNext={next} handleBack={prev} />
        </div>
      )}
      {stepCounter === 2 && (
        <div className="create-event-main-content-wapper flex-1">
          <Supplies data={data} handleNext={next} handleBack={prev} />{' '}
        </div>
      )}
      {stepCounter === 3 && (
        <div
          className="create-event-main-content-wapper flex-1"
          style={{ padding: '0px 40px 0px 40px' }}
        >
          <ManageTickets handleNext={next} handleBack={prev} />
        </div>
      )}
      {stepCounter === 4 && (
        <div className="create-event-main-content-wapper flex-1">
          <AdditionalEventSettings handleNext={next} handleBack={prev} />
          {''}
        </div>
      )}
    </AppLayout>
  )
}
const mapStateToProps = (state: any) => ({
  familyMembersData: state.family.familyMembersData,
  family: state.family.family,
  modalVisible: state.family.isModalVisible,
  currentEvent: state.events.currentEvent,
})
export default connect(mapStateToProps)(CreateEvent)
