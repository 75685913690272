import { create } from 'zustand'
import { StoredActivityType, inventoryStorageLocType } from 'types/events'
const eventCreationStore = (set: any) => ({
  // todo change it into the 0
  stepCounter: 0,
  setStepCounter: (state: number) => set(() => ({ stepCounter: state })),

  selectedDate: undefined,
  setSelectedDate: (state: Date) => set(() => ({ selectedDate: state })),

  activity: undefined,
  setActivity: (state: StoredActivityType) => set(() => ({ activity: state })),

  customActivity: undefined,
  setCustomActivity: (state: any) => set(() => ({ customActivity: state })),

  storageLocation: null,
  setStorageLocation: (state: inventoryStorageLocType) =>
    set(() => ({ storageLocation: state })),

  // todo change this to the null
  eventData: null,
  setEventData: (state: any) => set(() => ({ eventData: state })),
  requiredDetails: null,
  setRequiredDetails: (state: any) => set(() => ({ requiredDetails: state })),
  step5Payload: {},
  setStep5Payload: (state: any) => set(() => ({ step5Payload: state })),
})

export const creationEventStore = create(eventCreationStore)
