import React, { useEffect, useState } from 'react'
import { Modal, Tabs } from 'antd'
import closeIcon from 'assets/Images/close.svg'
import './styles/styles.scss'
import DefalutActivityTab from './DefalutActivityTab'
import SpecificActivtyTab from './SpecificActivtyTab'
import AddActivity from './addActivity/AddActivity'
import {
  defaultActivitiesResp,
  EventStepTwoPayload,
  GetActivitiesTypes,
} from 'types/events'
import { creationEventStore } from 'state/eventCreationStore'
import { useEventStepTwo } from 'api/EventCreation/CreationStep2'
import ButtonSpinner from 'components/global/ButtonSpinner'
import { CellClickEventArgs } from '@syncfusion/ej2-react-schedule'
import moment from 'moment'
import { convertTimeToUtcMinutes, calculateTimeDiffernce } from 'helper/utility'
import { convertDateToUtc } from '../../../utility'

interface EventArgs extends CellClickEventArgs {
  Id?: string
  StartTime?: Date
  EndTime?: Date
}

interface SelectActivityProps {
  modalOpen: boolean
  setmodalopen: React.Dispatch<React.SetStateAction<boolean>>
  defaultData?: EventArgs
}

const SelectActivityModal: React.FC<SelectActivityProps> = ({
  modalOpen,
  setmodalopen,
  defaultData,
}) => {
  const { _id, organizationId } = creationEventStore(store => store.eventData)
  const activity = creationEventStore(store => store.activity)
  const setActivity = creationEventStore(store => store.setActivity)
  const setEventData = creationEventStore(store => store.setEventData)
  const [addActivityModal, setAddActivityModal] = useState<boolean>(false)
  const [params, setParams] = useState<GetActivitiesTypes>({
    organizationId,
    defaultActivities: true,
  })
  const [selectedActivity, setSelectedActivity] =
    useState<defaultActivitiesResp>()
  const handleCancel = () => {
    setmodalopen(false)
  }
  const { mutate: eventStep2Mutation, isLoading: eventStep2Loading } =
    useEventStepTwo()

  const handleAddActivity = () => {
    const payload: EventStepTwoPayload = {
      isDefault: true,
      activitiesScheduleV2: {
        duration: activity.difference,
        days: [activity.date],
        eventActivityId: selectedActivity._id,
      },
    }

    eventStep2Mutation(
      { payload, eventId: _id, activityId: defaultData?.Id },
      {
        onSuccess: data => {
          setEventData(data)
          setSelectedActivity(undefined)
          handleCancel()
        },
      },
    )
  }

  const onChange = (key: string) => {
    if (key === '1') {
      setParams({ ...params, defaultActivities: true })
    } else {
      setParams({ ...params, defaultActivities: false })
    }
  }
  const items = [
    {
      key: '1',
      label: 'Default',
      children: (
        <DefalutActivityTab
          closeModal={setmodalopen}
          params={params}
          selected={selectedActivity}
          setSelected={setSelectedActivity}
        />
      ),
    },
    {
      key: '2',
      label: 'Specific',
      children: (
        <SpecificActivtyTab
          closeModal={setmodalopen}
          params={params}
          selected={selectedActivity}
          setSelected={setSelectedActivity}
        />
      ),
    },
  ]

  useEffect(() => {
    if (defaultData) {
      setActivity({
        ...activity,
        date: convertDateToUtc(defaultData.StartTime),
        startTime: convertTimeToUtcMinutes(
          moment(defaultData.StartTime).format('hh:mm a'),
        ),
        endTime: convertTimeToUtcMinutes(
          moment(defaultData.EndTime).format('hh:mm a'),
        ),
        difference: calculateTimeDiffernce(
          defaultData.StartTime,
          defaultData.EndTime,
          'minutes',
        ),
      })
    }
  }, [defaultData])

  return (
    <>
      <div>
        <Modal
          open={modalOpen}
          onCancel={handleCancel}
          className="select-activityModal"
          footer={false}
        >
          <div className="flex-center pt-20px justify-between ">
            <h2 className="w-600 text-18">Select activity</h2>
            <img
              src={closeIcon}
              alt="close-button"
              onClick={handleCancel}
              className="close button pointer"
            />
          </div>
          <div className="mt-10px text-center activity-select-container">
            <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
          </div>

          <div className="mt-30px flex align-center justify-between">
            <button
              className="create-activity-btn"
              onClick={() => setAddActivityModal(true)}
            >
              Create new activity
            </button>
            <button className="confirm-btn" onClick={handleAddActivity}>
              {eventStep2Loading ? (
                <ButtonSpinner size={20} color="#000" />
              ) : (
                'Confirm'
              )}
            </button>
          </div>
        </Modal>
      </div>
      <AddActivity
        modalOpen={addActivityModal}
        setModalOpen={setAddActivityModal}
      />
    </>
  )
}

export default SelectActivityModal
