import { Dropdown } from 'antd'
import React from 'react'
import Archive from '../../../../../assets/Images/Archive.svg'
import Chat from '../../../../../assets/Images/chat.svg'
import Attende from '../../../../../assets/Images/attendees.svg'
import Ticket from '../../../../../assets/Images/Ticket.svg'
import Rename from '../../../../../assets/Images/rename.svg'
import Register from '../../../../../assets/Images/register.svg'
import Live from '../../../../../assets/Images/liveUpdate.svg'
import Mentor from '../../../../../assets/Images/mentors.svg'
import EventManager from '../../../../../assets/Images/event-manager.svg'
import Copy from '../../../../../assets/Images/CopySimple.svg'
import Checkin from '../../../../../assets/Images/CheckSquare.svg'
import { useHistory } from 'react-router-dom'

interface ManageBtn {
  id: string
  name: string
  btnName?: string
}
const ManageButton: React.FC<ManageBtn> = ({ id, name, btnName }) => {
  const history = useHistory()
  const ManageEvent = [
    {
      key: 'contact',
      label: (
        <div className="flex align-center">
          <img src={Chat} alt="contac-attende" />
          <h4 className="w-500 text-14 ml-10px">Contact attendees</h4>
        </div>
      ),
    },
    {
      key: 'register',
      label: (
        <div className="flex align-center">
          <img src={Ticket} alt="contac-attende" />
          <h4 className="w-500 text-14 ml-10px">Register</h4>
        </div>
      ),
    },
    {
      key: 'check-in',
      label: (
        <div className="flex align-center">
          <img src={Checkin} alt="contac-attende" />
          <h4 className="w-500 text-14 ml-10px">Check-in</h4>
        </div>
      ),
    },
    {
      key: 'Live',
      label: (
        <div className="flex align-center">
          <img src={Live} alt="contac-attende" />
          <h4 className="w-500 text-14 ml-10px">Live Updates</h4>
        </div>
      ),
    },
    {
      key: 'edit',
      label: (
        <div className="flex align-center">
          <img src={Rename} alt="contac-attende" />
          <h4 className="w-500 text-14 ml-10px">Edit event</h4>
        </div>
      ),
    },
    {
      key: 'archive',
      label: (
        <div className="flex align-center">
          <img src={Archive} alt="contac-attende" />
          <h4 className="w-500 text-14 ml-10px">Archive event</h4>
        </div>
      ),
    },
    {
      key: 'duplicate',
      label: (
        <div className="flex align-center border-btm pb-15px">
          <img src={Copy} alt="contac-attende" />
          <h4 className="w-500 text-14 ml-10px">Duplicate event</h4>
        </div>
      ),
    },
    {
      key: 'attendees',
      label: (
        <div className="flex align-center">
          <img src={Attende} alt="contac-attende" />
          <h4 className="w-500 text-14 ml-10px">Attendees</h4>
        </div>
      ),
    },
    {
      key: 'Mentor',
      label: (
        <div className="flex align-center">
          <img src={Mentor} alt="contac-attende" />
          <h4 className="w-500 text-14 ml-10px">Mentors</h4>
        </div>
      ),
    },
    {
      key: 'Event',
      label: (
        <div className="flex align-center">
          <img src={EventManager} alt="contac-attende" />
          <h4 className="w-500 text-14 ml-10px">Event manager</h4>
        </div>
      ),
    },
  ]

  const onClick = ({ key }: any) => {
    if (key === 'attendees') history.push(`events/${id}/attendess`, { name })
    else if (key === 'edit') history.push(`events/create-event`, { id })
  }
  return (
    <Dropdown
      menu={{
        items: ManageEvent,
        onClick,
      }}
      placement="bottomLeft"
      arrow={false}
      className="mr-10px "
      overlayStyle={{ width: '250px' }}
    >
      <button className="w-500 prim-sm-btn">
        {btnName !== undefined ? btnName : 'Manage'}
      </button>
    </Dropdown>
  )
}
export default ManageButton
