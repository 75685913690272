import React, { useEffect, useState } from 'react'
import {
  Modal,
  Form,
  Row,
  Col,
  Input,
  Radio,
  Select,
  RadioChangeEvent,
} from 'antd'
import { creationEventStore } from 'state/eventCreationStore'
import closeIcon from 'assets/Images/close.svg'
import '../../styles/styles.scss'
import StorageLocationModal from './StorageLocationModal'
import {
  AddInventoryPayload,
  InventoryResponse,
  inventoryStorageLocType,
} from 'types/events'
import AddGroup from './AddGroup'
import {
  useAddInventoryByOrg,
  useEditInventory,
} from 'api/EventCreation/CreationStep2'
import ButtonSpinner from 'components/global/ButtonSpinner'
import { infoMessage } from 'api/global'
import UploadDocument from 'components/global/UploadDocument'

const { Option } = Select

interface AddSupplyFormProps {
  modalOpen: boolean
  modalClose: React.Dispatch<React.SetStateAction<boolean>>
  data?: InventoryResponse
}

const AddSupplyForm: React.FC<AddSupplyFormProps> = ({
  modalOpen,
  modalClose,
  data,
}) => {
  const [form] = Form.useForm()
  const activity = creationEventStore(store => store.customActivity)
  const setCustomActivity = creationEventStore(store => store.setCustomActivity)
  const [type, setType] = useState(data ? data.type : '')
  const storageLocation: inventoryStorageLocType = creationEventStore(
    store => store.storageLocation,
  )
  const setStorageLocation = creationEventStore(
    store => store.setStorageLocation,
  )

  const { organizationId } = creationEventStore(store => store.eventData)
  const [priceType, setPriceType] = useState<string>(
    data?.priceRange ? 'price-range' : 'price',
  )
  const [documentIds, setDocumentIds] = useState<string[]>([])
  console.log(documentIds, 'idsss from')
  const [storageModalVisible, setStorageModalVisible] = useState<boolean>(false)
  const [openGroupModal, setOpenGroupModal] = useState<boolean>(false)
  const [groups, setGroups] = useState<string[]>([])
  const handleCancel = () => {
    modalClose(false)
  }

  const { mutate: addInventory, isLoading: addInventoryLoading } =
    useAddInventoryByOrg()
  const { mutate: updateInventory, isLoading: updateInventoryLoading } =
    useEditInventory()

  const weightUnits = [
    {
      _id: 1,
      name: 'pounds',
    },
    {
      _id: 2,
      name: 'ounces',
    },
    {
      _id: 3,
      name: 'kilograms',
    },
    {
      _id: 4,
      name: 'grams',
    },
  ]

  const formSchema = {
    name: data ? data.name : '',
    type: data ? data.type : '',
    noOfTimes: data ? data?.noOfTimes : undefined,
    groupSize: data ? data?.groupSize : undefined,
    link: data ? data?.link : undefined,
    quantity: data ? data.totalQuantity : undefined,
    weight: data ? data.weight.value : undefined,
    weightUnit: data ? data.weight.unit : undefined,
    price: data?.price ? data.price : undefined,
    minPrice: data?.priceRange ? data.priceRange.min : undefined,
    maxPrice: data?.priceRange ? data.priceRange.max : undefined,
    description: data ? data?.description : undefined,
  }

  //   functions
  const handleStorageClick = () => {
    setStorageModalVisible(true)
  }
  const handleGroupTitle = () => {
    setOpenGroupModal(true)
  }

  const hanldeTypeChange = (event: RadioChangeEvent) => {
    setType(event.target.value)
  }

  const onFinish = (values: any) => {
    if (!storageLocation) {
      return infoMessage('storage location is required')
    }
    if (!values.name) {
      return infoMessage('Supply name is required')
    }
    if (!values.type) {
      return infoMessage('Type is required')
    }
    if (!values.quantity) {
      return infoMessage('Quantity is required')
    }
    if (!values.weight) {
      return infoMessage('Weight is required')
    }
    if (!values.weightUnit) {
      return infoMessage('Weight unit is required')
    }
    const payload: AddInventoryPayload = {
      storageLocations: [
        {
          locationId: storageLocation._id,
          quantity: {
            value: storageLocation.quantity,
            unit: storageLocation.unit,
          },
        },
      ],
      name: values.name,
      type: values.type,
      groupSize: values.groupSize,
      noOfTimes: values.noOfTimes,
      price: priceType === 'price' ? values.price : undefined,
      priceRange:
        priceType !== 'price'
          ? { min: values.minPrice, max: values.maxPrice }
          : undefined,
      image: documentIds[0],
      description: values.description,
      group: groups,
      weight: {
        value: values.weight,
        unit: values.weightUnit,
      },
      totalQuantity: values.quantity,
      link: values.link,
    }

    if (data) {
      updateInventory(
        { payload, activityId: activity._id, supplyId: data._id },
        {
          onSuccess: data => {
            setCustomActivity(data)
            handleCancel()
          },
        },
      )
    } else {
      if (organizationId) {
        addInventory(
          { payload, organizationId },
          {
            onSuccess: data => {
              handleCancel()
            },
          },
        )
      }
    }
  }

  useEffect(() => {
    if (data) {
      setStorageLocation(data.storageLocations[0].locationId)
    }
  }, [data])

  return (
    <>
      <div>
        <Modal
          open={modalOpen}
          onCancel={handleCancel}
          className="add-supplyform-modal"
          footer={false}
        >
          <div className="flex-center pt-20px justify-between ">
            <h2 className="w-600 text-18">Supplies</h2>
            <img
              src={closeIcon}
              alt="close-button"
              onClick={handleCancel}
              className="close button pointer"
            />
          </div>

          <div className="h-33rem overflow-auto-y scroller overflow-hidden-x">
            <Form
              name="add-supply-form"
              initialValues={formSchema}
              form={form}
              onFinish={onFinish}
              layout="vertical"
              autoComplete="off"
            >
              <Row>
                <Col span={24}>
                  <label htmlFor="" className="input-label required-input">
                    Supply name
                  </label>
                  <Form.Item colon={false} name="name" className="form-control">
                    <Input
                      placeholder="Enter name"
                      name="name"
                      className="form-control-field"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  <label htmlFor="" className="input-label">
                    Storage location
                  </label>
                  {!storageLocation ? (
                    <div
                      className="border-1px radius-8 px-15px py-15px mb-16px"
                      onClick={handleStorageClick}
                    >
                      <span className="text-14 text-gray">Select</span>
                    </div>
                  ) : (
                    <>
                      <div className="border-1px radius-8 px-15px py-15px mb-16px">
                        <h3 className="text-14 w-600">
                          {storageLocation.title}
                        </h3>
                        <p>{`${storageLocation.city}, ${storageLocation.street}`}</p>
                        {storageLocation?.zip && (
                          <div className="flex justify-between align-center">
                            <p>Zip</p>
                            <p>{storageLocation?.zip}</p>
                          </div>
                        )}
                        <div className="flex justify-between align-center">
                          <p>Quantity</p>
                          <p>{storageLocation.quantity}</p>
                        </div>
                        <div className="flex justify-between align-center">
                          <p>Unit</p>
                          <p>{storageLocation.unit}</p>
                        </div>
                      </div>

                      <button
                        className="mt-15px big-btn w-full mb-15px"
                        onClick={handleStorageClick}
                      >
                        Add location
                      </button>
                    </>
                  )}
                </Col>
              </Row>

              <Row>
                <Col>
                  <label className="input-label required-input">Type</label>
                </Col>
                <Col>
                  <Form.Item className="form-control" name="type">
                    <Radio.Group onChange={e => hanldeTypeChange(e)}>
                      <Radio value="consumable">Consumable</Radio>
                      <Radio value="reusable">Reuseable</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>

              {type === 'reusable' ? (
                <Row>
                  <Col className="flex align-center">
                    <div
                      style={{ width: '100%' }}
                      className="relative text-12 w-600"
                    >
                      Number of times
                    </div>
                    <Form.Item name="noOfTimes" style={{ width: '20%' }}>
                      <Input
                        type="number"
                        placeholder="0"
                        className="text-end radius-6"
                        onWheel={e => e.currentTarget.blur()}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col className="flex align-center">
                    <div
                      style={{ width: '100%' }}
                      className="relative text-12 w-600"
                    >
                      Group size
                    </div>
                    <Form.Item name="groupSize" style={{ width: '20%' }}>
                      <Input
                        type="number"
                        placeholder="0"
                        className="text-end radius-6"
                        onWheel={e => e.currentTarget.blur()}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              )}

              <Row>
                <Col span={24}>
                  <label htmlFor="" className="input-label">
                    Link
                  </label>
                  <Form.Item colon={false} name="link" className="form-control">
                    <Input
                      placeholder="Url"
                      name="link"
                      className="form-control-field"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col className="flex align-center relative">
                  <div
                    style={{ width: '100%' }}
                    className="relative text-12 w-600 required-input"
                  >
                    Quantity
                  </div>
                  <Form.Item name="quantity" style={{ width: '20%' }}>
                    <Input
                      type="number"
                      placeholder="0"
                      className="text-end radius-6"
                      onWheel={e => e.currentTarget.blur()}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col className="flex align-center relative">
                  <div
                    style={{ width: '100%' }}
                    className="relative text-12 w-600 required-input"
                  >
                    Weight
                  </div>
                  <Form.Item name="weight" style={{ width: '20%' }}>
                    <Input
                      type="number"
                      placeholder="0"
                      className="text-end radius-6"
                      onWheel={e => e.currentTarget.blur()}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col>
                  <label className="input-label required-input">
                    Weight unit
                  </label>
                </Col>
                <Col style={{ width: '100%' }}>
                  <Form.Item className="form-control" name="weightUnit">
                    <Select className="form-control-field" placeholder="Select">
                      {weightUnits.map(item => (
                        <Option
                          value={item.name}
                          key={item._id}
                          name={item.name}
                          _id={item._id}
                        >
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <h3 className="text-20px w-600">Costs</h3>

              <Row gutter={10} className="mt-10px">
                <Col>
                  <Form.Item name="priceType" className="w-100">
                    <Col span={24} className="d-flex">
                      <label
                        className="input-label mr-30 mb-10 align-self-center w-100 mb-0px"
                        style={{ maxWidth: '90px' }}
                      >
                        Price type
                      </label>
                    </Col>
                    <Radio.Group
                      onChange={(val: RadioChangeEvent) => {
                        setPriceType(val.target.value)
                      }}
                      defaultValue={priceType}
                    >
                      <div className="flex justify-between align-center w-70p">
                        <Radio value={'price'} className="mr-40 radio">
                          Price
                        </Radio>
                        {priceType === 'price' && (
                          <div className="flex align-center">
                            <p className="mr-10px">$</p>
                            <Form.Item name="price" className="mb-0px">
                              <Input
                                placeholder="0"
                                className="br-12 custom-input-num-field font16 text-end"
                                type="number"
                                onWheel={e => e.currentTarget.blur()}
                              />
                            </Form.Item>
                          </div>
                        )}
                      </div>
                      <div className="flex gap-16px align-center">
                        <Radio value={'price-range'} className="mr-40 radio">
                          Price range
                        </Radio>
                        {priceType === 'price-range' && (
                          <div className="flex gap-16px align-center">
                            <div className="flex align-center">
                              <p className="mr-10px">$</p>
                              <Form.Item name="minPrice" className="mb-0px">
                                <Input
                                  placeholder="0"
                                  className="br-12 custom-input-num-field font16 text-end"
                                  type="number"
                                  onWheel={e => e.currentTarget.blur()}
                                />
                              </Form.Item>
                            </div>
                            -
                            <div className="flex align-center">
                              <p className="mr-10px">$</p>
                              <Form.Item name="maxPrice" className="mb-0px">
                                <Input
                                  placeholder="0"
                                  className="br-12 custom-input-num-field font16 text-end"
                                  type="number"
                                  onWheel={e => e.currentTarget.blur()}
                                />
                              </Form.Item>
                            </div>
                          </div>
                        )}
                      </div>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col>
                  <label className="custom-field-label">Image</label>
                </Col>
                <Col span={16}>
                  <UploadDocument
                    documentIds={documentIds}
                    setDocumentIds={setDocumentIds}
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  <label className="input-label">Description</label>
                </Col>
                <Col>
                  <Form.Item className="form-control" name="description">
                    <Input.TextArea
                      className="form-control-field"
                      placeholder="Short description of the supply"
                      style={{ height: '90px' }}
                    ></Input.TextArea>
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col>
                  <label className="input-label">Group Title</label>
                </Col>
                <Col>
                  {/* <Form.Item className="form-control" name="groupTitle">
                    <Input
                      className="form-control-field"
                      placeholder="Group title"
                      readOnly
                      value={groups.map(group => `${group},`)}
                      onClick={handleGroupTitle}
                    ></Input>
                  </Form.Item> */}
                  <div
                    className="w-full border-1px radius-10 flex align-center px-12px pointer h-50px"
                    onClick={handleGroupTitle}
                  >
                    {groups.length > 0
                      ? groups.map(group => `${group}, `)
                      : 'Add group'}
                  </div>
                </Col>
              </Row>

              <div className="mt-10px flex align-center justify-between">
                <button className="create-activity-btn" onClick={handleCancel}>
                  Cancel
                </button>
                <button className="confirm-btn">
                  {addInventoryLoading || updateInventoryLoading ? (
                    <ButtonSpinner size={20} color="#000" />
                  ) : data ? (
                    'Edit supply'
                  ) : (
                    'Add new supply'
                  )}
                </button>
              </div>
            </Form>
          </div>
        </Modal>
      </div>

      {storageModalVisible && (
        <StorageLocationModal
          openModal={storageModalVisible}
          closeModal={setStorageModalVisible}
        />
      )}

      {openGroupModal && (
        <AddGroup
          modalOpen={openGroupModal}
          closeModal={setOpenGroupModal}
          groups={groups}
          setGroups={setGroups}
        />
      )}
    </>
  )
}

export default AddSupplyForm
