import { Modal, Switch } from 'antd'
import React from 'react'
import closeIcon from 'assets/Images/close.svg'
import { creationEventStore } from 'state/eventCreationStore'
import { RequiredDeatilsTypes } from 'types/eventStep5'

interface RequiredDetailsModalprops {
  openModal: boolean
  closeModal: React.Dispatch<React.SetStateAction<boolean>>
}
const RequiredDetailsModal: React.FC<RequiredDetailsModalprops> = ({
  openModal,
  closeModal,
}) => {
  const eventData = creationEventStore(store => store.eventData)
  const setEventData = creationEventStore(store => store.setEventData)
  const requiredDetails = creationEventStore(store => store.requiredDetails)
  const setRequiredDetails = creationEventStore(
    store => store.setRequiredDetails,
  )
  const handleCancel = () => {
    closeModal(false)
  }

  const hanldeSwitchClick = (
    item: RequiredDeatilsTypes,
    type: 'guardian' | 'adult' | 'kid',
  ) => {
    const updateDetails = (list: RequiredDeatilsTypes[]) => {
      return list.map(entry => {
        return entry.key === item.key
          ? { ...entry, value: !entry.value }
          : entry
      })
    }

    let updatedDetails
    if (type === 'guardian') {
      updatedDetails = { guardians: updateDetails(requiredDetails.guardians) }
    } else if (type === 'adult') {
      updatedDetails = { adults: updateDetails(requiredDetails.adults) }
    } else if (type === 'kid') {
      updatedDetails = { kids: updateDetails(requiredDetails.kids) }
    }

    setRequiredDetails({ ...requiredDetails, ...updatedDetails })

    setEventData({
      ...eventData,
      requiredDetails: { ...requiredDetails, ...updatedDetails },
    })
  }

  return (
    <div>
      <Modal
        open={openModal}
        onCancel={handleCancel}
        className="required-details-modal"
        footer={false}
        width={600}
        centered
      >
        <div className="flex-center pt-20px justify-between ">
          <h2 className="w-600 text-18">Required details</h2>
          <img
            src={closeIcon}
            alt="close-button"
            onClick={handleCancel}
            className="close button pointer"
          />
        </div>
        <div className="mt-15px">
          <p>
            Select what input fileds shoudl be mandatory at the event
            registration
          </p>
          <div className="h-30rem overflow-auto-y scroller">
            <div className="mt-10px">
              <h3 className="text-16 w-600">Parent details</h3>
              <div>
                {requiredDetails.guardians.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="flex align-center justify-between py-10px border-btm"
                    >
                      <p>{item.title}</p>
                      <Switch
                        checked={item.value}
                        onChange={() => hanldeSwitchClick(item, 'guardian')}
                      />
                    </div>
                  )
                })}
              </div>
            </div>
            <div className="mt-10px">
              <h3 className="text-16 w-600">Adult details</h3>
              <div>
                {requiredDetails.adults.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="flex align-center justify-between py-10px border-btm"
                    >
                      <p>{item.title}</p>
                      <Switch
                        checked={item.value}
                        onChange={() => hanldeSwitchClick(item, 'adult')}
                      />
                    </div>
                  )
                })}
              </div>
            </div>
            <div className="mt-10px">
              <h3 className="text-16 w-600">Kid details</h3>
              <div>
                {requiredDetails.kids.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="flex align-center justify-between py-10px border-btm"
                    >
                      <p>{item.title}</p>
                      <Switch
                        checked={item.value}
                        onChange={() => hanldeSwitchClick(item, 'kid')}
                      />
                    </div>
                  )
                })}
              </div>
            </div>

            {/* <div className="mt-30px flex gap-20px justify-end align-center">
              <button className="confirm-btn pointer" onClick={handleCancel}>
                Save
              </button>
            </div> */}
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default RequiredDetailsModal
