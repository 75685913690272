import React, { useState } from 'react'
import IconsTextNavigation from 'components/global/IconsTextNavigation'
import AddTemplateToEvent from '../../Modals/ConfirmationEmail/AddTemplateToEvent'
import { creationEventStore } from 'state/eventCreationStore'

const ConfirmationEmail: React.FC = () => {
  const [openEmailModal, setOpenEmailModal] = useState<boolean>(false)
  const eventData = creationEventStore(store => store.eventData)
  return (
    <>
      <div onClick={() => setOpenEmailModal(true)}>
        <IconsTextNavigation
          iconName="EnvelopeSimple"
          heading="Confirmation email"
          details={
            // eventData.emailTemplates.length > 0 ? 'Email created' : 'No email'
            eventData?.emailTemplates ? 'Email created' : 'No email'
          }
        />
      </div>
      {openEmailModal && (
        <AddTemplateToEvent
          openModal={openEmailModal}
          closeModal={setOpenEmailModal}
        />
      )}
    </>
  )
}

export default ConfirmationEmail
