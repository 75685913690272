import React, { useState, useEffect, useRef } from 'react'
import { Button, Checkbox, Col, Modal, Row } from 'antd'
import { CellClickEventArgs } from '@syncfusion/ej2-react-schedule'
import closeIcon from 'assets/Images/close.svg'
import { weekDays } from '../../../utility'
import './styles/styles.scss'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import { creationEventStore } from 'state/eventCreationStore'
import moment from 'moment'
import { useCopyActivityInDays } from 'api/EventCreation/CreationStep2'

interface EventArgs extends CellClickEventArgs {
  Id?: string
  StartTime?: Date
  EndTime?: Date
}
type DayType = (typeof weekDays)[number]

interface CopyActivityToDaysProps {
  modalOpen: boolean
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  defaultData?: EventArgs
}

const CopyActivityToDays: React.FC<CopyActivityToDaysProps> = ({
  modalOpen,
  setModalOpen,
  defaultData,
}) => {
  const { datesOfEvent, _id } = creationEventStore(store => store.eventData)
  const setEventData = creationEventStore(store => store.setEventData)
  const [selectedDays, setSelectedDays] = useState<DayType[]>([])
  const { mutate: copyActivity, isLoading: copyActivityLoading } =
    useCopyActivityInDays()
  const handleCancel = () => {
    setModalOpen(false)
  }

  const hanldeDayCheck = (e: CheckboxChangeEvent, selectedDay: DayType) => {
    if (e.target.checked) {
      setSelectedDays(prev => [...prev, selectedDay])
    } else {
      const updatedDays = selectedDays.filter(
        day => day.toLocaleLowerCase() !== selectedDay,
      )
      setSelectedDays(updatedDays)
    }
  }

  const filterDatesBySelectedDays = () => {
    if (!datesOfEvent.length || !selectedDays.length) return

    const filtered = datesOfEvent.filter(dateString => {
      const date = moment(dateString)
      const dayOfWeek = date.format('dddd').toLowerCase()
      return selectedDays.includes(dayOfWeek)
    })

    return filtered
  }

  const handleCopyClick = () => {
    const payload = {
      activityId: defaultData.Id,
      pasteDates: filterDatesBySelectedDays(),
    }
    copyActivity(
      { payload, eventId: _id },
      {
        onSuccess: data => {
          setEventData(data)
          handleCancel()
        },
      },
    )
  }

  return (
    <div>
      <Modal
        className="days-modal"
        open={modalOpen}
        onCancel={handleCancel}
        centered={true}
        footer={null}
      >
        <div className="flex-center pt-20px justify-between ">
          <h2 className="w-600 text-18">Select days to copy activity</h2>
          <img
            src={closeIcon}
            alt="close-button"
            onClick={handleCancel}
            className="close button pointer"
          />
        </div>
        <div className="h-20 mt-30px">
          {weekDays.map((day, index) => {
            return (
              <div
                key={index}
                className="flex justify-between align-center mt-10px day-modal-check"
              >
                <p className="text-14 w-500">{day}</p>
                <Checkbox
                  onChange={e => hanldeDayCheck(e, day.toLocaleLowerCase())}
                />
              </div>
            )
          })}
        </div>
        <div className="mt-30px flex align-center justify-between">
          <button className="create-activity-btn">Cancel</button>
          <button className="confirm-btn" onClick={handleCopyClick}>
            Copy activity
          </button>
        </div>
      </Modal>
    </div>
  )
}

export default CopyActivityToDays
