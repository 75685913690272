import moment from 'moment'

export const convertDateToUtc = (startDateTime: Date | string) => {
  const formatedDate = moment(startDateTime)
    .utc()
    .set({
      hour: moment(startDateTime, 'HH:mm').utc().hour(),
      minute: moment(startDateTime, 'HH:mm').utc().minute(),
    })
    .format()
  return formatedDate
}

export const weekDays = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
]

export const AlertModalData = (
  type: 'admission' | 'additional' | 'dropin' | 'discount',
  modalType: 'delete' | 'duplicate',
) => {
  return {
    name: 'Tickets',
    heading: `${
      modalType === 'delete' ? 'Delete' : 'Duplicate'
    } ${type}  Ticket!`,
    message1: `Are you sure you want to ${
      modalType === 'delete' ? 'Delete' : 'Duplicate'
    } this ${type} ticket?`,
  }
}

export const AlertModalGeneric = (name: string, heading: string) => {
  return {
    name: name,
    heading: `Delete ${heading}`,
    message1: `Are you sure you want to delete this ${name}?`,
  }
}
