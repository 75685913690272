import { Col, Form, Input, Modal, Row, Select } from 'antd'
import React from 'react'
import closeIcon from 'assets/Images/close.svg'
import { useCreateEmailTemplate } from 'api/EmailTemplate'
import { ConditionsToSend, EmailTemplate } from 'types/globalTypes'
import { creationEventStore } from 'state/eventCreationStore'
import { EmailTemplateCreation } from 'types/eventStep5'
import ButtonSpinner from 'components/global/ButtonSpinner'
interface TemplateDetailsProps {
  openModal: boolean
  closeModal: React.Dispatch<React.SetStateAction<boolean>>
  body: string
  closeParent: () => void
}

const TemplateDetails: React.FC<TemplateDetailsProps> = ({
  openModal,
  closeModal,
  body,
  closeParent,
}) => {
  const [form] = Form.useForm()
  const { organizationId } = creationEventStore(store => store.eventData)
  const { mutate: createTemplatemutate, isLoading: createTemplateLoading } =
    useCreateEmailTemplate()

  const formSchema = {}
  const onFinish = (values: EmailTemplateCreation) => {
    const payload: EmailTemplate = {
      body,
      type: 'event',
      templateName: values.templateName,
      conditionsToSend: {
        timeQuantity: values.timeQuantity,
        timePeriod: values.timePeriod,
        timeFrame: values.timeFrame,
        conditionType: values.conditionType,
      },
    }
    createTemplatemutate(
      { payload, orgId: organizationId },
      {
        onSuccess: () => {
          closeParent()
        },
      },
    )
  }
  const handleCancel = () => {
    closeModal(false)
  }

  return (
    <div>
      <Modal
        open={openModal}
        onCancel={handleCancel}
        className="confirmation-email"
        footer={false}
        width={700}
        centered
      >
        <div className="flex-center pt-20px justify-between ">
          <h2 className="w-600 text-18">confirmation email template</h2>
          <img
            src={closeIcon}
            alt="close-button"
            onClick={handleCancel}
            className="close button pointer"
          />
        </div>
        <div className="mt-20px h-25rem overflow-auto-y overflow-hidden-x scroller">
          <Form
            form={form}
            layout="vertical"
            initialValues={formSchema}
            onFinish={onFinish}
          >
            <Row gutter={10}>
              <Col>
                <label className="input-label required-input">
                  Template name
                </label>
                <Form.Item
                  className="form-control"
                  name="templateName"
                  rules={[
                    { required: true, message: 'Template naem is required' },
                  ]}
                >
                  <Input
                    className="custom-input-field"
                    placeholder="Template name"
                  />
                </Form.Item>
              </Col>
            </Row>

            <div>
              <label className="input-label">Condition to send</label>
              <div className="flex gap-16px align-center w-full">
                <div style={{ width: '20%' }}>
                  <Form.Item className="form-control" name="timeQuantity">
                    <Input
                      type="number"
                      className="custom-input-field"
                      placeholder="0"
                    />
                  </Form.Item>
                </div>

                <div style={{ width: '25%' }}>
                  <Form.Item
                    className="pointer selector-input"
                    name="timePeriod"
                  >
                    <Select
                      className="select-input-field type-select-field"
                      placeholder="Select"
                      optionLabelProp="label"
                      options={[
                        {
                          value: 'mins',
                          label: 'Minutes',
                        },
                        {
                          value: 'days',
                          label: 'Days',
                        },
                        {
                          value: 'weeks',
                          label: 'Weeks',
                        },
                      ]}
                    />
                  </Form.Item>
                </div>

                <div style={{ width: '25%' }}>
                  <Form.Item
                    className="pointer selector-input"
                    name="timeFrame"
                  >
                    <Select
                      className="select-input-field type-select-field"
                      placeholder="Select"
                      optionLabelProp="label"
                      options={[
                        {
                          value: 'before',
                          label: 'Before',
                        },
                        {
                          value: 'after',
                          label: 'After',
                        },
                      ]}
                    />
                  </Form.Item>
                </div>

                <div style={{ width: '30%' }}>
                  <Form.Item
                    className="pointer selector-input"
                    name="conditionType"
                  >
                    <Select
                      className="select-input-field type-select-field"
                      placeholder="Select"
                      optionLabelProp="label"
                      options={[
                        {
                          value: 'event-registration',
                          label: 'Event registration',
                        },
                        {
                          value: 'registration-edit',
                          label: 'Registration edit',
                        },
                        {
                          value: 'ticket-start-date',
                          label: 'Start date',
                        },
                        {
                          value: 'ticket-end-date',
                          label: 'End date',
                        },
                      ]}
                    />
                  </Form.Item>
                </div>
              </div>
            </div>

            <div
              className="px-20px py-15px radius-12 mt-20px"
              style={{ background: '#FFF9E1' }}
            >
              <p className="text-14 w-400">
                Want to trigger an action immediately after something else
                occurs? Set its timing to 0 minutes, days, etc.{' '}
              </p>
            </div>

            <Row
              className="justify-end gap-16px"
              style={{ marginTop: '8.4rem' }}
            >
              <button className="confirm-btn" type="submit">
                {createTemplateLoading ? (
                  <ButtonSpinner size={20} color="#000" />
                ) : (
                  'Confirm and save'
                )}
              </button>
            </Row>
          </Form>
        </div>
      </Modal>
    </div>
  )
}

export default TemplateDetails
