import React, { useEffect, useState } from 'react'
import { Form, Input, Button, Row, Col, Avatar, Select } from 'antd'
import 'antd-country-phone-input/dist/index.css'
import 'antd/dist/antd.css'
import 'antd-country-phone-input/dist/index.css'
import moment from 'moment'
import SelectOrganizerModal from './GeneralDetailModal/SelectOrganizerModal'
import EventManager from './InputComps/EventManager'
import OrganizationInput from './InputComps/OrganizationInput'
import ButtonSpinner from 'components/global/ButtonSpinner'
import {
  OrgOrganizerParams,
  OrgOrganizerType,
  Step1BodyTypes,
  OrganizationVenues,
  SpecialSchedule,
} from 'types/organization'
import {
  useGetEventCategories,
  useGetOrgOrganizers,
  useCreateEventStep1Mutation,
  useEditEventStep1,
} from 'api/EventCreation/EventCreation'
import { CategoryType, Address, OrganizationResp } from 'types/globalTypes'
import Loader from 'helper/Loader'
import { infoMessage } from 'api/global'
import DateandTimeInputs from './InputComps/DateandTimeInputs'
import VenuType from './InputComps/VenuType'
import { creationEventStore } from 'state/eventCreationStore'
import CategoryInput from './InputComps/CategoryInput'
import { convertMinutesToLocalTime } from 'helper/utility'

interface GeneralDetailsTypes {
  handleNext: (childData: any) => void
}

const GeneralDetail: React.FC<GeneralDetailsTypes> = ({ handleNext }) => {
  const eventData = creationEventStore(store => store.eventData)
  const setEventData = creationEventStore(store => store.setEventData)
  const setRequiredDetails = creationEventStore(
    store => store.setRequiredDetails,
  )
  const [form] = Form.useForm()
  const { Option } = Select
  const [isUpdated, setIsUpdated] = useState<boolean>(false)
  const [categoryVal, setCategoryVal] = useState<CategoryType>()
  const [selectedOrganization, setSelectedOrganization] =
    useState<OrganizationResp>()
  const [selectedOrganizer, setSelectedOrganizer] = useState<string[]>([])
  const [specialScheduleData, setSpecialScheduleData] = useState([])
  const [venueDataItem, setVenueDataItem] =
    useState<OrganizationVenues | null>()
  const [mapData, setMapModalData] = useState<Address | null>(null)
  const [isEventOnline, setEventOnline] = useState<boolean>(false)
  const [isDefaultOrganizer, setIsDefaultOrganizer] = useState<boolean>(true)
  const [defaultOrganizer, setDefaultOrganizer] = useState(null)
  const [eventTime, setEventTime] = useState({
    startTime: undefined,
    endTime: undefined,
  })
  const [params, setParams] = useState<OrgOrganizerParams>({
    status: 'approved',
  })

  const formSchema = {
    venueType: eventData ? eventData.venueType : 'physical',
  }

  useEffect(() => {}, [selectedOrganization])

  useEffect(() => {}, [selectedOrganizer])

  useEffect(() => {
    form.setFieldsValue({ venue: venueDataItem?.name })
  }, [venueDataItem])

  useEffect(() => {
    form.setFieldsValue({ organizationId: selectedOrganization?.name })
    setSelectedOrganization(selectedOrganization)
  }, [selectedOrganization])

  const { mutate: createStep1, isLoading: createStep1Loading } =
    useCreateEventStep1Mutation()
  const { mutate: editStep1, isLoading: editStepLoading } = useEditEventStep1()

  const onFinish = (values: Step1BodyTypes) => {
    if (!selectedOrganization) {
      infoMessage('Organization is required')
      return
    }
    if (!values.name) {
      infoMessage('Event name is required')
      return
    }
    if (!categoryVal) {
      infoMessage('Category is required')
      return
    }
    if (!values.venueType) {
      infoMessage('Venue type is required')
      return
    }
    if (selectedDates.length === 0) {
      infoMessage('Event dates are required')
      return
    }
    if (selectedOrganization && values.venueType === 'physical') {
      if (!mapData) {
        infoMessage('Location is required')
        return
      }
      if (!venueDataItem) {
        infoMessage('Venue is required')
        return
      }
    }

    let dataValues = { ...values }
    let temp = []
    temp.push(defaultOrganizer.id)
    dataValues.organizerIds = isDefaultOrganizer ? temp : selectedOrganizer

    dataValues.location = values.venueType === 'physical' ? mapData : undefined
    dataValues.datesOfEvent = selectedDates.map((item: string) =>
      moment(
        new Date(moment(item).format('YYYY-MM-DDTHH:mm:ss[Z]')).setHours(
          parseInt(moment(eventTime.startTime).utc().format('HH:mm'), 10),
          0,
          0,
          0,
        ),
      ).format('YYYY-MM-DDTHH:mm:ss[Z]'),
    )
    dataValues.name = values.name
    dataValues.category = {
      _id: categoryVal._id,
      name: categoryVal.name,
    }
    dataValues.venueType = values.venueType
    dataValues.venueId =
      values.venueType === 'physical'
        ? venueDataItem
          ? venueDataItem?._id
          : ''
        : undefined
    dataValues.timings = {
      startTime:
        parseInt(moment(eventTime.startTime).utc().format('HH:mm'), 10) * 60,
      endTime:
        parseInt(moment(eventTime.endTime).utc().format('HH:mm'), 10) * 60,
      specialSchedules: specialScheduleData.map((data: SpecialSchedule) => ({
        ...data,
        day: moment(data.day, 'YYYY-MM-DD').format('YYYY-MM-DDTHH:mm:ss[Z]'),
      })),
    }

    dataValues.minGuestsRequired = values.minGuestsRequired
    dataValues.maxGuestsAllowed = values.maxGuestsAllowed
    dataValues.guestsRequiredToIncreaseMax = values.guestsRequiredToIncreaseMax
    dataValues.dailyGuestLimit = values.dailyGuestLimit
    if (eventData) {
      editStep1(
        { body: dataValues, eventId: eventData._id },
        {
          onSuccess: result => {
            setIsUpdated(true)
            setEventData(result)
            setRequiredDetails(result.requiredDetails)
            handleNext({ ...values, location: mapData })
          },
        },
      )
    } else {
      createStep1(
        { body: dataValues, orgId: selectedOrganization.id },
        {
          onSuccess: result => {
            setIsUpdated(true)
            setEventData(result)
            setRequiredDetails(result.requiredDetails)
            handleNext({ ...values, location: mapData })
          },
        },
      )
    }
  }

  const setFieldsValue = (data: any) => {
    if (data?.address || data?.country) {
      form.setFieldsValue({ location: data?.title })
    } else {
      form.setFieldsValue({ categories: data?.categories })
    }
  }

  const [selectedDates, setSelectedDates] = useState<string[]>([])
  const {
    data: eventCategories,
    isLoading: eventCategoryLoading,
    refetch: eventCategoryRefetch,
  } = useGetEventCategories()

  const {
    data: orgOrganizers,
    isLoading: orgOrganizersLoading,
    refetch: orgOrganizersRefetch,
  } = useGetOrgOrganizers(params, selectedOrganization?.id)

  useEffect(() => {
    eventCategoryRefetch()
  }, [])
  useEffect(() => {
    if (selectedOrganization) {
      orgOrganizersRefetch()
    }
  }, [selectedOrganization])

  useEffect(() => {
    if (orgOrganizers) {
      let temp = orgOrganizers?.find(
        (item: OrgOrganizerType) => item.accessLevel === 'owner',
      )
      setDefaultOrganizer(temp.organizer)
      setSelectedOrganizer([temp?.organizerId])
    }
  }, [orgOrganizers])

  //edit the data

  useEffect(() => {
    if (!isUpdated && eventData) {
      form.setFieldsValue({
        name: eventData.name,
        venueType: eventData.venueType || 'physical',
        dailyGuestLimit: eventData?.dailyGuestLimit,
        minGuestsRequired: eventData?.minGuestsRequired,
        maxGuestsAllowed: eventData?.maxGuestsAllowed,
        guestsRequiredToIncreaseMax: eventData?.guestsRequiredToIncreaseMax,
      })

      setCategoryVal(eventData.category)
      setSelectedOrganization(eventData.organization)
      setSelectedOrganizer(
        eventData?.organizers?.map(
          (organizer: OrgOrganizerType) => organizer._id,
        ),
      )
      setVenueDataItem(eventData.venue)
      setMapModalData(eventData.location)
      setEventOnline(eventData.venueType === 'virtual')
      setEventTime({
        startTime: moment(
          convertMinutesToLocalTime(eventData?.timings.startTime, 'HH:mm'),
          'HH:mm',
        ),
        endTime: moment(
          convertMinutesToLocalTime(eventData?.timings.endTime, 'HH:mm'),
          'HH:mm',
        ),
      })
      setSelectedDates(
        eventData.datesOfEvent.map((date: string) =>
          moment(date).format('YYYY-MM-DD'),
        ),
      )
    }
  }, [eventData])

  return (
    <>
      {eventCategoryLoading ? (
        <Loader />
      ) : (
        <div>
          <Form
            form={form}
            layout="horizontal"
            initialValues={formSchema}
            onFinish={onFinish}
          >
            <div
              className="create-event-main-content-box"
              style={{ margin: '0 auto' }}
            >
              <h2>Step 1. General Details</h2>
              <OrganizationInput
                selectedOrganization={selectedOrganization}
                setSelectedOrganization={setSelectedOrganization}
              />
              {orgOrganizers?.length > 0 && (
                <EventManager
                  selectedOrganizer={selectedOrganizer}
                  setSelectedOrganizer={setSelectedOrganizer}
                  orgOrganizers={orgOrganizers}
                  defaultOrganizer={defaultOrganizer}
                />
              )}

              <Row gutter={10}>
                <Col>
                  <label className="input-label required-input">
                    Event name
                  </label>
                </Col>
                <Col>
                  <Form.Item className="form-control" name="name">
                    <Input
                      placeholder="Event name"
                      className="form-control-field"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <CategoryInput
                category={categoryVal}
                setCategory={setCategoryVal}
              />

              <VenuType
                isEventOnline={isEventOnline}
                setEventOnline={setEventOnline}
                selectedOrganization={selectedOrganization}
                mapData={mapData}
                setMapModalData={setMapModalData}
                venueDataItem={venueDataItem}
                setVenueDataItem={setVenueDataItem}
              />

              <DateandTimeInputs
                selectedDates={selectedDates}
                setSelectedDates={setSelectedDates}
                specialScheduleData={specialScheduleData}
                setSpecialScheduleData={setSpecialScheduleData}
                eventTime={eventTime}
                setEventTime={setEventTime}
              />
              <Row style={{ marginTop: 15 }}>
                <Col>
                  <h4 className="b1 mb-15">Guests limitation</h4>
                </Col>
                <Col className="d-flex align-items-center">
                  <div style={{ width: '100%' }}>
                    <span>Daily guests Limit</span>
                  </div>
                  <Form.Item name="dailyGuestLimit" style={{ width: '20%' }}>
                    <Input
                      type="number"
                      placeholder="0"
                      className="text-end br-5"
                    />
                  </Form.Item>
                </Col>
                <Col className="d-flex align-items-center">
                  <div style={{ width: '100%' }}>
                    <span>Minimum guests required</span>
                  </div>
                  <Form.Item name="minGuestsRequired" style={{ width: '20%' }}>
                    <Input
                      type="number"
                      placeholder="0"
                      className="text-end br-5"
                    />
                  </Form.Item>
                </Col>
                <Col className="d-flex align-items-center">
                  <div style={{ width: '100%' }}>
                    <span>Maximum guests required</span>
                  </div>
                  <Form.Item name="maxGuestsAllowed" style={{ width: '20%' }}>
                    <Input
                      type="number"
                      placeholder="0"
                      className="text-end br-5"
                    />
                  </Form.Item>
                </Col>
                <Col className="d-flex align-items-center">
                  <div style={{ width: '100%' }}>
                    <span>Guests required to increase max</span>
                  </div>
                  <Form.Item
                    name="guestsRequiredToIncreaseMax"
                    style={{ width: '20%' }}
                  >
                    <Input
                      type="number"
                      placeholder="0"
                      className="text-end br-5"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
            <Row justify="end" className="mb-26 event-step-cont-btn">
              <Button
                className="stepper-button"
                disabled={createStep1Loading}
                htmlType="submit"
              >
                {createStep1Loading || editStepLoading ? (
                  <ButtonSpinner color="#000" size={20} />
                ) : (
                  'Next'
                )}
              </Button>
            </Row>
          </Form>
        </div>
      )}
    </>
  )
}

export default GeneralDetail
