import { Col, Modal, Radio, RadioChangeEvent, Row } from 'antd'
import React, { SetStateAction, useState } from 'react'
import closeIcon from 'assets/Images/close.svg'
import { useEventAdditionalSetting } from 'api/EventCreation/creationStep5'
import { creationEventStore } from 'state/eventCreationStore'
import { EventStatus } from 'types/eventStep5'
import { useHistory } from 'react-router-dom'
import ButtonSpinner from 'components/global/ButtonSpinner'

interface ConfirmationmodalProps {
  openModal: boolean
  closeModal: React.Dispatch<SetStateAction<boolean>>
}

const Confirmationmodal: React.FC<ConfirmationmodalProps> = ({
  openModal,
  closeModal,
}) => {
  const history = useHistory()
  const [eventStatus, setEventStatus] = useState<EventStatus>('draft')
  const eventData = creationEventStore(store => store.eventData)
  const setEventData = creationEventStore(store => store.setEventData)
  const setStepCounter = creationEventStore(store => store.setStepCounter)
  const payload = creationEventStore(store => store.step5Payload)
  const { mutate: step5Mutate, isLoading: step5Loading } =
    useEventAdditionalSetting()
  const handleCancel = () => {
    closeModal(false)
  }

  const handleRadioChange = (e: RadioChangeEvent) => {
    setEventStatus(e.target.value)
  }

  const handleSaveEvent = () => {
    step5Mutate(
      { payload: { ...payload, status: eventStatus }, eventId: eventData._id },
      {
        onSuccess: data => {
          setEventData(null)
          setStepCounter(0)
          history.push(`/events`)
        },
      },
    )
  }

  return (
    <div>
      <Modal
        open={openModal}
        onCancel={handleCancel}
        className="confirmation-event-modal"
        footer={false}
        width={480}
        centered
      >
        <div className="px-20px">
          <div>
            <div className="flex-center pt-20px justify-end ">
              <img
                src={closeIcon}
                alt="close-button"
                onClick={handleCancel}
                className="close button pointer"
              />
            </div>
            <h2 className="text-24 w-600 text-center">
              How would you like to save this event?
            </h2>
          </div>
          <div className="h-22rem scroller overflow-auto-y overflow-hidden-x">
            <Radio.Group defaultValue={'draft'} onChange={handleRadioChange}>
              <Row>
                <Col className="d-flex justify-between align-items-start mt-20">
                  <div>
                    <h2 className="text-16 w-600">Draft</h2>
                    <p className="text-14 w-400 text-gray mt-5px">
                      Only visible to organizers
                    </p>
                  </div>
                  <Radio className="radio mr-0" value={'draft'} />
                </Col>
              </Row>
              <Row>
                <Col className="d-flex justify-between align-items-start mt-20">
                  <div>
                    <h2 className="text-16 w-600">Private</h2>
                    <p className="text-14 w-400 text-gray mt-5px">
                      Only visible by shared link
                    </p>
                  </div>
                  <Radio className="radio mr-0" value={'private'} />
                </Col>
              </Row>
              <Row>
                <Col className="d-flex justify-between align-items-start mt-20">
                  <div>
                    <h2 className="text-16 w-600">Following</h2>
                    <p className="text-14 w-400 text-gray mt-5px">
                      Visible to all families following the organization
                    </p>
                  </div>
                  <Radio className="radio mr-0" value={'following'} />
                </Col>
              </Row>
              <Row>
                <Col className="d-flex justify-between align-items-start mt-20">
                  <div>
                    <h2 className="text-16 w-600">Subscribed</h2>
                    <p className="text-14 w-400 text-gray mt-5px">
                      Visible to all families subscribed and approved the
                      organization
                    </p>
                  </div>
                  <Radio className="radio mr-0" value={'subscribed'} />
                </Col>
              </Row>
              <Row>
                <Col className="d-flex justify-between align-items-start mt-20">
                  <div>
                    <h2 className="text-16 w-600">Mentor social</h2>
                    <p className="text-14 w-400 text-gray mt-5px">
                      Visible to Mentors on their separate app
                    </p>
                  </div>
                  <Radio className="radio mr-0" value={'subscribed'} />
                </Col>
              </Row>
              <Row>
                <Col className="d-flex justify-between align-items-start mt-20">
                  <div>
                    <h2 className="text-16 w-600">Public</h2>
                    <p className="text-14 w-400 text-gray mt-5px">
                      Visible to all families
                    </p>
                  </div>
                  <Radio className="radio mr-0" value={'public'} />
                </Col>
              </Row>
            </Radio.Group>
          </div>

          <div className="mt-40px flex gap-20px justify-between align-center">
            <button className="btn-secondary">Cancel</button>
            <button className="confirm-btn pointer" onClick={handleSaveEvent}>
              {step5Loading ? <ButtonSpinner size={20} color="#000" /> : 'Save'}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default Confirmationmodal
