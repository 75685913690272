import React, { useEffect, useState } from 'react'
import { Modal } from 'antd'
import closeIcon from 'assets/Images/close.svg'
import plusBlue from 'assets/Images/plusBlue.svg'
import { creationEventStore } from 'state/eventCreationStore'
import {
  useGetEventFaq,
  useGetOrganizationFaq,
} from 'api/EventCreation/creationStep5'
import Spinner from 'components/Spinner'
import FaqContainer from './FaqContainer'
import { EventFaqs, OrgFaqs } from 'types/eventStep5'
import AddQuestion from './AddQuestion'

interface FaqModalProps {
  openModal: boolean
  closeMdoal: React.Dispatch<React.SetStateAction<boolean>>
}
const FaqModal: React.FC<FaqModalProps> = ({ openModal, closeMdoal }) => {
  const { organizationId, _id } = creationEventStore(store => store.eventData)
  const payload: any = creationEventStore(store => store.step5Payload)
  const setStep5Payload = creationEventStore(store => store.setStep5Payload)
  const [openAddQuestion, setOpenAddQuestion] = useState<boolean>(false)
  const [selectedQuestion, setSelectedQuestion] = useState<string[]>(
    payload?.faqIds || [],
  )
  const {
    data: orgFaqs,
    isLoading: orgFaqsLoading,
    isFetching: orgFaqsFetching,
    refetch: orgFaqRefetch,
  } = useGetOrganizationFaq(organizationId)
  const {
    data: eventFaqs,
    isLoading: eventFaqsLoading,
    isFetching: eventFaqsFetching,
    refetch: eventFaqRefetch,
  } = useGetEventFaq(_id)
  const handleCancel = () => {
    closeMdoal(false)
  }

  const hanldeSaveClick = () => {
    setStep5Payload({
      ...payload,
      faqIds: selectedQuestion,
    })
    handleCancel()
  }

  useEffect(() => {
    orgFaqRefetch()
    eventFaqRefetch()
  }, [organizationId, _id])

  return (
    <div>
      <Modal
        open={openModal}
        onCancel={handleCancel}
        className="items-t0-bring"
        footer={false}
        width={600}
        maskClosable={false}
        centered
      >
        <div className="flex-center pt-20px justify-between ">
          <h2 className="w-600 text-18">Faq</h2>
          <img
            src={closeIcon}
            alt="close-button"
            onClick={handleCancel}
            className="close button pointer"
          />
        </div>
        <div className="h-25rem overflow-auto-y scroller">
          <h3 className="text-16 w-600 mt-20px">General Question</h3>
          <p className="text-gray w-400 text-14">
            Select questions that could be interested for potential visitors of
            your event
          </p>
          {orgFaqsLoading ||
          orgFaqsFetching ||
          eventFaqsLoading ||
          eventFaqsFetching ? (
            <div className="h-full flex align-center justify-center">
              <Spinner />
            </div>
          ) : (
            <>
              <div className="mt-10px">
                {orgFaqs.length > 0 ? (
                  orgFaqs.map((faq: OrgFaqs) => {
                    return (
                      <div key={faq._id} className="mt-10px">
                        <FaqContainer
                          data={faq}
                          questions={selectedQuestion}
                          setQuestion={setSelectedQuestion}
                        />
                      </div>
                    )
                  })
                ) : (
                  <p className="text-14 w-400 text-gray">No questions</p>
                )}
              </div>
              <div className="mt-10px">
                <h3 className="text-16 w-600 mt-20px">
                  Event specific question
                </h3>
                {eventFaqs.length > 0 ? (
                  eventFaqs.map((faq: EventFaqs) => {
                    return (
                      <div className="mt-10px">
                        <FaqContainer
                          data={faq}
                          questions={selectedQuestion}
                          setQuestion={setSelectedQuestion}
                        />
                      </div>
                    )
                  })
                ) : (
                  <p className="text-14 w-400 text-gray">No questions</p>
                )}
              </div>
            </>
          )}
        </div>
        <div className="mt-30px flex gap-20px justify-between align-center">
          <button
            className="pointer flex align-center gap-16px simple-btn"
            onClick={() => setOpenAddQuestion(true)}
          >
            <img src={plusBlue}></img> <p>Add Question</p>
          </button>
          <button className="confirm-btn pointer" onClick={hanldeSaveClick}>
            Save
          </button>
        </div>
      </Modal>

      {openAddQuestion && (
        <AddQuestion
          openModal={openAddQuestion}
          closeModal={setOpenAddQuestion}
        />
      )}
    </div>
  )
}

export default FaqModal
