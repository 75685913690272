import React, { useEffect, useState } from 'react'
import SearchBar from 'helper/SearchBar'
import {
  useGetEventDefaultActivities,
  useEventStepTwo,
} from 'api/EventCreation/CreationStep2'
import { creationEventStore } from 'state/eventCreationStore'
import {
  GetActivitiesTypes,
  defaultActivitiesResp,
  EventStepTwoPayload,
} from 'types/events'
import Spinner from 'components/Spinner'
import ViewActivity from './ViewActivity'

interface DefalutActivityTabProps {
  closeModal: React.Dispatch<React.SetStateAction<boolean>>
  params: GetActivitiesTypes
  selected: defaultActivitiesResp
  setSelected: React.Dispatch<React.SetStateAction<defaultActivitiesResp>>
}

const DefalutActivityTab: React.FC<DefalutActivityTabProps> = ({
  closeModal,
  params,
  selected,
  setSelected,
}) => {
  const [activityId, setActivityId] = useState<string>()
  const [activityModal, setActivityModal] = useState<boolean>(false)
  const { data, isLoading, isFetching, refetch } =
    useGetEventDefaultActivities(params)

  const handleView = (activityId: string) => {
    setActivityId(activityId)
    setActivityModal(true)
  }

  useEffect(() => {
    refetch()
  }, [params])

  return (
    <>
      {isLoading || isFetching ? (
        <div className="mt-30px mx-auto flex justify-center">
          <Spinner />
        </div>
      ) : (
        <div>
          <div className="mt-15px gay-searchbar">
            <SearchBar />
          </div>
          <div className="default-activity h-17rem overflow-auto-y scroller">
            {data.map((activity: defaultActivitiesResp) => (
              <div
                className="flex align-center justify-between mt-20px"
                key={activity._id}
              >
                <h3 className="w-500 text-16">{activity.name}</h3>
                <div className="flex align-center gap-10px">
                  <button
                    className={`btn`}
                    onClick={() => handleView(activity._id)}
                  >
                    View
                  </button>
                  <button
                    className={`btn ${
                      selected?._id === activity._id && 'selected-activity'
                    }`}
                    onClick={() => setSelected(activity)}
                  >
                    {selected?._id === activity._id ? 'Selected' : 'Select'}
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {activityModal && (
        <ViewActivity
          modalOpen={activityModal}
          modalClose={setActivityModal}
          type={'default'}
          activityId={activityId}
        />
      )}
    </>
  )
}

export default DefalutActivityTab
