import axios from 'axios'
import { axiosGet } from '../Redux/axiosHelper'
import { RequestParms } from '../types/globalTypes'
import { BASE_URL, failureMessage, successMessage } from './global'

export const getOrganizers = async (parms: RequestParms) => {
  const response = await axiosGet('v1/admins/organizers', parms)
  return response.data
}
export const updateOrganizerStatus = async (
  params: string,
  payload: string,
) => {
  const response = await axios.patch(
    `${BASE_URL}v1/organizers/${params}/status`,
    { status: payload },
  )
  response.status === 200
    ? successMessage('Status updated Successfully !')
    : failureMessage('Error while updating Status')
}
export const deleteOrganizer = async (id: string) => {
  const response = await axios.delete(`${BASE_URL}v1/organizers/${id}`)
  response.status === 200
    ? successMessage('Organizer Deleted Successfully !')
    : failureMessage('Error while Deleting Organizer')
}
export const confirmOrganizerImportData = async (payload: any) => {
  try {
    const response = await axios.put(`${BASE_URL}v1/imports/organizer`, payload)
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(`Unexpected status code: ${response.status}`)
    }
  } catch (error) {
    failureMessage(`${error.response.data.message}`)
    return null
  }
}
export const importOrganizerFile = async (payload: any) => {
  // const response = await axios.post(`${BASE_URL}v1/imports/organizer`, payload)
  // console.log(response)
  //  if(response.status === 200){
  //   return response.data
  //  }else{
  //   console.log(response?.data)
  //   failureMessage(`Error:${response.statusText}`)
  //   failureMessage(`Error:${response.status}`)

  try {
    const response = await axios.post(
      `${BASE_URL}v1/imports/organizer`,
      payload,
    )
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(`Unexpected status code: ${response.status}`)
    }
  } catch (error) {
    failureMessage(`${error.response.data.message}`)
    return null
  }
}
export const confirmEmail = async (organizerId: string, payload: string) => {
  // const response = await axios.patch(
  //   `${BASE_URL}v1/organizers/change-email/${organizerId}`,
  //   { newEmail: payload },
  // )
  // response.status === 200
  //   ? successMessage('Email change Successfully !')
  //   : failureMessage('Error while changing Email')
  try {
    const response = await axios.patch(
      `${BASE_URL}v1/users/change-email/${organizerId}`,
      payload,
    )
    if (response.status === 200) {
      successMessage('Email change Successfully !')
      return response.data
    } else {
      failureMessage(response?.data?.message || 'An error occurred')
      return Promise.reject(response.data)
    }
  } catch (error) {
    if (error.response) {
      failureMessage(error.response.data.message || 'An error occurred')
    } else {
      failureMessage('An error occurred')
    }
  }
}
export const blockEmail = async (payload: string) => {
  try {
    const response = await axios.patch(
      `${BASE_URL}v1/users/block-email`,
      payload,
    )
    if (response.status === 200) {
      successMessage('Email block Successfully !')
      return response.data
    } else {
      failureMessage(response?.data?.message || 'An error occurred')
      return Promise.reject(response.data)
    }
  } catch (error) {
    if (error.response) {
      failureMessage(error.response.data.message || 'An error occurred')
    } else {
      failureMessage('An error occurred')
    }
  }
}
