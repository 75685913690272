import React, { useEffect, useState } from 'react'
import IconsTextNavigation from 'components/global/IconsTextNavigation'
import RequiredDetailsModal from '../../Modals/RequiredDetailsModal'
import { creationEventStore } from 'state/eventCreationStore'

const RequiredDetails: React.FC = () => {
  const [requiredDetailsModal, setRequiredDetails] = useState<boolean>(false)
  const [isAdded, setIsAdded] = useState<boolean>(false)
  const requiredDetails = creationEventStore(store => store.requiredDetails)
  // const { requiredDetails } = creationEventStore(store => store.eventData)

  useEffect(() => {
    const hasSelected = Object.keys(requiredDetails).some(category =>
      requiredDetails[category].some(item => item.value === true),
    )
    setIsAdded(hasSelected)
  }, [requiredDetails])

  return (
    <>
      <div onClick={() => setRequiredDetails(true)}>
        <IconsTextNavigation
          iconName="CheckCircle"
          heading="Required Details"
          details={isAdded ? 'Added' : 'Not added'}
        />
      </div>
      {requiredDetailsModal && (
        <RequiredDetailsModal
          openModal={requiredDetailsModal}
          closeModal={setRequiredDetails}
        />
      )}
    </>
  )
}

export default RequiredDetails
