import React, { useState, useEffect } from 'react'
import {
  ScheduleComponent,
  Day,
  Inject,
  CellClickEventArgs,
  PopupOpenEventArgs,
} from '@syncfusion/ej2-react-schedule'
import { creationEventStore } from 'state/eventCreationStore'
import { CaretLeft, CaretRight } from '@phosphor-icons/react'
import moment from 'moment'
import { infoMessage } from 'api/global'
import { convertMinutesToLocalTime } from 'helper/utility'
import ActivityTime from '../Modals/ActivityTime'
import ActivityMenu from './CustomMeuuOptions'
import { Dropdown, Button } from 'antd'
import './styles/schedular.scss'
import SelectActivityModal from '../Modals/SelectActivityModal'
import AlertModal from 'helper/AlertModal'
import { useDeleteEventActivity } from 'api/EventCreation/CreationStep2'
import CopyActivityToDays from '../Modals/CopyActivityDays'

type MenuEventTypes =
  | 'view'
  | 'delete-activity'
  | 'copy-activity'
  | 'edit-time'
  | 'overlapping'
  | 'change-activity'
interface cellDataType extends CellClickEventArgs {
  Id?: string
}

const ActivityCalendar: React.FC = () => {
  const { datesOfEvent, timings, activitiesScheduleV2, _id } =
    creationEventStore(store => store.eventData)
  const setEventData = creationEventStore(store => store.setEventData)
  const selectedDate = creationEventStore(store => store.selectedDate)
  const setSelectedDate = creationEventStore(store => store.setSelectedDate)
  const [menuKey, setMenuKey] = useState<MenuEventTypes>()
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [selectActivityModal, setSelectActivityModal] = useState<boolean>(false)
  const [deleteModal, setDeleteModal] = useState<boolean>(false)
  const [daysModal, setDaysModal] = useState<boolean>(false)
  const [cellData, setCellData] = useState<cellDataType>()
  const [specialSchedule, setSpecialSchedule] = useState<any>()
  const [data, setData] = useState<any>()
  const [menuVisible, setMenuVisible] = useState(false)
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 })
  const [clickedEvent, setClickedEvent] = useState<any>(null)
  const timeScale = { enable: true, interval: 60, slotCount: 4 }

  const alertModalData = {
    name: 'Activity',
    heading: 'Delete the Activity',
    message1: `Are you sure you want to delete the activity?`,
  }

  const { mutate: deleteActivity, isLoading: deleteActivityLoading } =
    useDeleteEventActivity()

  const eventSettings = { dataSource: data }

  const onCellClick = (args: CellClickEventArgs) => {
    args.cancel = true
    openCustomPopup(args)
  }

  const onCellDoubleClick = (args: CellClickEventArgs) => {
    args.cancel = true
    openCustomPopup(args)
  }

  const openCustomPopup = (args: CellClickEventArgs) => {
    setCellData(args)
    setOpenModal(true)
  }

  const handlePopupOpen = (args: PopupOpenEventArgs) => {
    if (args.type === 'QuickInfo' || args.type === 'Editor') {
      args.cancel = true
    }
  }

  const onEventRendered = args => {
    const eventElement = args.element
    const event = args.data
    eventElement.style.backgroundColor =
      event.Subject === 'Check-in'
        ? '#FDEAD2'
        : event.Subject === 'Breakfast'
        ? '#D5E1F4'
        : event.Subject === 'Lunch'
        ? '#8CC73F33'
        : event.Subject === 'Dinner'
        ? '#D5EDF4'
        : event.Subject === 'Check-out'
        ? '#E8769F33'
        : '#47BFDF33'
    eventElement.style.color = '#ffffff'
    eventElement.style.borderRadius = '0px'
    eventElement.style.borderTop = '1px solid #fff'
    eventElement.style.borderBottom = '1px solid #fff'
    eventElement.classList.add('custom-event')
    eventElement.innerHTML = `
      <div class="custom-event-template">
        <div class="custom-event-header">${event.Subject}</div>
        <div class="custom-event-body">
          <div class="custom-event-time">
            ${new Date(event.StartTime).toLocaleTimeString()} - ${new Date(
              event.EndTime,
            ).toLocaleTimeString()}
          </div>
        </div>
        <div class="custom-menu-opener flex justify-center align-center">
            <div class="dot-container">
              <div class="dot"></div>
              <div class="dot"></div>
              <div class="dot"></div>
            </div>
        </div>
      </div>
    `

    eventElement.addEventListener('click', e => {
      e.preventDefault()
      e.stopPropagation()
      handleEventClick(event, e)
    })

    const menuOpener = eventElement.querySelector('.custom-menu-opener')
    if (menuOpener) {
      menuOpener.addEventListener('click', e => {
        e.stopPropagation()
        handleMenuOpenerClick(event, e)
      })
    }
  }

  const handleEventClick = (event, e) => {
    console.log('Event clicked:', event)
  }

  const handleMenuOpenerClick = (event, e) => {
    e.preventDefault()
    const { clientX: x, clientY: y } = e
    setMenuPosition({ x, y })
    setClickedEvent(event)
    setMenuVisible(prev => !prev)
  }

  const handleMenuClick = (key: MenuEventTypes) => {
    setMenuVisible(false)
    setMenuKey(key)
    switch (key) {
      case 'view':
        console.log('key', key, clickedEvent)
        break
      case 'change-activity':
        setCellData(clickedEvent)
        setSelectActivityModal(true)
        break
      case 'overlapping':
        setCellData(clickedEvent)
        setOpenModal(true)
        break
      case 'edit-time':
        setOpenModal(true)
        setCellData(clickedEvent)
        break
      case 'copy-activity':
        setCellData(clickedEvent)
        setDaysModal(true)
        break
      case 'delete-activity':
        setCellData(clickedEvent)
        setDeleteModal(true)
        break
      default:
        break
    }
  }

  // utility functions
  const findCurrentIndex = () => {
    for (let i = 0; i < datesOfEvent.length; i++) {
      if (
        moment(datesOfEvent[i]).format('DD-MM-YYYY') ===
        moment(selectedDate).format('DD-MM-YYYY')
      ) {
        return i
      }
    }
  }

  const handleNextDate = () => {
    const currentIndex = findCurrentIndex()
    if (currentIndex < datesOfEvent.length - 1) {
      setSelectedDate(new Date(datesOfEvent[currentIndex + 1]))
    } else {
      infoMessage('Date does not exist in event')
    }
  }

  const handlePrevDate = () => {
    const currentIndex = findCurrentIndex()
    if (currentIndex > 0) {
      setSelectedDate(new Date(datesOfEvent[currentIndex - 1]))
    } else {
      infoMessage('Date does not exists in event')
    }
  }

  const onYesClick = () => {
    deleteActivity(
      {
        payload: {
          activityIds: [cellData.Id],
        },
        eventId: _id,
      },
      {
        onSuccess: data => {
          setEventData(data)
          setDeleteModal(false)
        },
      },
    )
  }

  useEffect(() => {
    const hasSpecialSchedule = timings?.specialSchedules?.find(schedule => {
      return (
        moment(selectedDate).format('MMMM-ddd-yyyy') ===
        moment(schedule.day).format('MMMM-ddd-yyyy')
      )
    })
    if (hasSpecialSchedule) {
      setSpecialSchedule(hasSpecialSchedule)
    } else {
      setSpecialSchedule(undefined)
    }
  }, [selectedDate])

  useEffect(() => {
    const activityShower = activitiesScheduleV2.map((activity: any) => {
      const startTime = new Date(activity.days[0])
      const endTime = new Date(activity.days[0])
      endTime.setMinutes(endTime.getMinutes() + activity.duration)
      return {
        Id: activity._id,
        eventActivityId: activity.eventActivityId,
        Subject: activity.name,
        StartTime: startTime,
        EndTime: endTime,
        IsAllDay: false,
      }
    })
    setData(activityShower)
  }, [activitiesScheduleV2])

  return (
    <>
      <div className="activity-header">
        <div className="carret-cont" onClick={handlePrevDate}>
          <CaretLeft size={20} color="#000" />
        </div>
        <h3>{moment(selectedDate).format('DD MMMM')}</h3>
        <div className="carret-cont" onClick={handleNextDate}>
          <CaretRight size={20} color="#000" />
        </div>
      </div>

      <ScheduleComponent
        key={specialSchedule ? specialSchedule.day : 'default'}
        width="100%"
        height="550px"
        currentView="Day"
        showHeaderBar={false}
        cssClass="activity-scheduel-calendar"
        timeScale={timeScale}
        selectedDate={selectedDate}
        startHour={
          specialSchedule
            ? convertMinutesToLocalTime(specialSchedule.startTime, 'HH:mm')
            : convertMinutesToLocalTime(timings.startTime, 'HH:mm')
        }
        endHour={
          specialSchedule
            ? convertMinutesToLocalTime(specialSchedule.endTime, 'HH:mm')
            : convertMinutesToLocalTime(timings.endTime, 'HH:mm')
        }
        eventSettings={eventSettings}
        cellClick={onCellClick}
        cellDoubleClick={onCellDoubleClick}
        eventRendered={onEventRendered}
        popupOpen={handlePopupOpen}
      >
        <Inject services={[Day]} />
      </ScheduleComponent>

      <ActivityTime
        openModal={openModal}
        setOpenModal={setOpenModal}
        defaultData={cellData}
        specialSchedule={specialSchedule}
        operation={menuKey}
      />

      {deleteModal && (
        <AlertModal
          isModalVisible={deleteModal}
          setIsModalVisible={setDeleteModal}
          data={alertModalData}
          onYesClick={onYesClick}
          loading={deleteActivityLoading}
        />
      )}

      {daysModal && (
        <CopyActivityToDays
          modalOpen={daysModal}
          setModalOpen={setDaysModal}
          defaultData={cellData}
        />
      )}

      <SelectActivityModal
        modalOpen={selectActivityModal}
        setmodalopen={setSelectActivityModal}
        defaultData={cellData}
      />

      {menuVisible && (
        <Dropdown
          overlay={<ActivityMenu handleMenuClick={handleMenuClick} />}
          open={menuVisible}
          onOpenChange={setMenuVisible}
        >
          <div
            style={{
              position: 'absolute',
              left: menuPosition.x - 150,
              top: menuPosition.y - 20,
            }}
          >
            <Button type="text" />
          </div>
        </Dropdown>
      )}
    </>
  )
}

export default ActivityCalendar
