import { useEffect } from 'react'
import './style.scss'
import { Quill } from 'react-quill'
import { values } from 'lodash'

var Font = Quill.import('formats/font')
Font.whitelist = [
  'monospace',
  'serif',
  'raleway',
  'sans-serif',
  'montserrat',
  'lato',
  'rubik',
  'roboto',
  'poppins',
]

export const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ font: Font.whitelist }],
    [{ size: ['small', 'normal', 'large'] }],
    [{ color: [] }, { background: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link', 'image'],
  ],
}

export const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'color',
  'background',
]

export const customValues = [
  {
    name: 'Parent name',
    value: '{{parent_name}}',
  },
  {
    name: 'Organization name',
    value: '{{organization_name}}',
  },
  {
    name: 'Event name',
    value: '{{event_name}}',
  },
  {
    name: 'Parent name',
    value: '{{parent_name}}',
  },
  {
    name: 'Attendees',
    value: '{{attendees}}',
  },
  {
    name: 'Admission ticket',
    value: '{{admission_ticket}}',
  },
  {
    name: 'Additional ticket',
    value: '{{additional_ticket}}',
  },
]
